import { httpRequest } from '@/service/requests'
let BASE_URL = import.meta.env.PROD ? "/wcyapi" : "/wcyapi";

// 查询商城-商品-分类列表
export function getCategory() {
  return httpRequest.get(`${BASE_URL}/mall/goods/cate/list`)
}

// 获取手机型号
export function getPhoneModel() {
  return httpRequest.post(`${BASE_URL}/mall/goods/cate/mobile`)
}

// 根据分类获取sku
export function getCategorySku(id) {
  return httpRequest.get(`${BASE_URL}/mall/goods/sku/cid/${id}`)
}

// 查询我的草稿
export function getMyDraft(data, pageNum) {
  return httpRequest.post(`${BASE_URL}/user/goods/list?pageSize=100&pageNum=${pageNum}`, data)
}

// 保存到我的草稿
export function saveMyDraft(data) {
  return httpRequest.post(`${BASE_URL}/user/goods/save-draft`, data)
}

// 判断是否有缓存
export function getCache(params) {
  return httpRequest.get('/webapp/api/v1/diy/cache', params)
}

// 上传
export function upload(params) {
  return httpRequest.upload(`${BASE_URL}/global/user/upload`, params)
}

// 根据key获取对应枚举
export function qrDictType(dictType) {
  return httpRequest.get(`${BASE_URL}/global/public/dict/type/${dictType}`)
}

// 缓存
export function saveMyCache(params) {
  return httpRequest.put(`/webapp/api/v1/diy/cache`, params)
}

// 商品详情
export function getGoodsInfo(id) {
  return httpRequest.get(`${BASE_URL}/mall/goods/info/details/${id}`)
}

// 发布草稿
export function publishGoods(id) {
  return httpRequest.post(`${BASE_URL}/user/goods/release/${id}`)
}

// 图片处理
export function effectsImage(params) {
  return httpRequest.post('/webapp/api/v1/diy/album/effects', params)
}

// 获取diy页面数据
export function getDiyData(params) {
  return httpRequest.get('/webapp/api/v1/diy', params)
}

// 获取素材分类
export function getAlbumList(params) {
  return httpRequest.post(`${BASE_URL}/assets/album/list`, params)
}

// 获取字体列表
export function getFontList() {
  return httpRequest.get(`${BASE_URL}/assets/album/font/list`)
}

// 获取专辑下的图片
export function getAlbumImage(id) {
  return httpRequest.get(`${BASE_URL}/assets/album/img/${id}`)
}

// 百度抠图
export function cutoutImage(params, text) {
  // return httpRequest.post(`${BASE_URL}/global/user/cutout`, params, text)
  return httpRequest.upload(`${BASE_URL}/global/user/cutout/stream`, params, text)
}

// 宠物抠图
export function petCutoutImage(params, text) {
  return httpRequest.upload(`${BASE_URL}/global/user/voe/cutout/stream`, params, text)
}

// 物品抠图
export function articleCutoutImage(params, text) {
  return httpRequest.upload(`${BASE_URL}/global/user/aliy/cutout/stream`, params, text)
}

// 获取热门标签
export function getHotTags() {
  return httpRequest.get(`${BASE_URL}/mall/tag/tagged/type/1`)
}

// 根据标签获取模板
export function getHotTagGoods(id, current, cateId) {
  return httpRequest.get(`${BASE_URL}/mall/tag/tagged/${id}?pageNum=${current}&pageSize=20&cateId=${cateId}`)
}

//高清
export function clearnessImage(params) {
  return httpRequest.post(`${BASE_URL}/global/user/clearness`, params)
}

// 根据id获取字体地址
export function getFontUrl(id) {
  return httpRequest.get(`${BASE_URL}/assets/album/font/${id}`)
}

// 删除草稿
export function delMydraft(id) {
  return httpRequest.post(`${BASE_URL}/user/goods/delete/${id}`)
}

// 图片检测
export function imageDetection(params) {
  return httpRequest.post(`${BASE_URL}/douyin/image/detection`, params)
}

// 文字检测
export function textDetection(params) {
  return httpRequest.post(`${BASE_URL}/douyin/content/detection`, params)
}

// shopify创建商品
export function createCustomerProduct(params) {
  return httpRequest.post(`/hades/api/shopify/createCustomerProduct`, params)
}

// 登录
export function shopifyLogin(params) {
  return httpRequest.post(`/wcyapi/shopify/login`, params)
}

// 获取商品详情
export function qGoodsDetail(id) {
  return httpRequest.get(`/wcyapi/mall/goods/cate/${id}`)
}