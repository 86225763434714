// en.js
export default {
    save: "PRINT",
    changeStyle: "Change Style",
    cacheTips: "You have an unfinished work, do you want to continue?",
    ContinuingCreate: "Continue Edit",
    discard: "Undo",
    stickers: "Stickers",
    font: "Font",
    image: "Image",
    loading: "Loading...",
    templates: "Templates",
    clearCavans: "Clear",
    drafts: "Drafts",
    amplify: "Enlarge",
    reduce: "Shrink",
    layers: "Layers",
    back: "Back",
    fore: "Fore",
    saveMydrafts: "Has been saved to my draft box",
    selectedMaterial: "Please reselect the material",
    make: "In production",
    noPhoneCase: "There are no phone cases available for sale under this phone",
    t_shirt: "Short Sleeve T-shirt",
    long_t_shirt: "Long Sleeve T-shirt",
    crewneckHoodie: "Crew Neck Sweatshirt",
    hoodie: "Hooded Sweater",
    noClothes: "There are no products for sale under this category",
    noDrafts: "There is currently no draft available",
    myDrafts: "My Drafts",
    noMore: "No More",
    typeHere: "Type here",
    style: "Style",
    fonts: "Font",
    patternedText: "Patterned text",
    clothingColor: "Clothing Color",
    patternSize: "Pattern size",
    largeArea: "Large Area",
    nextStep: "Next Step",
    loadError: "Load Error",
    customized: "Design It",
    selectModel: "Select Model",
    noData: "No Data",
    deleteSuccess: "Delete successful",
    switch: "Switch",
    model: "Model",
    brand: "Brand",
    publish: "Publishing works",
    Continuing: "Continuing the design",
    delete: "Delete",
    cutout: "Cutout",
    hd: "HD",
    adjust: "Level",
    fineTuning: "Fine tuning",
    overturn: "Flip",
    copy: "Copy",
    layerAdjuster: "Layer Adjuster",
    topLayer: "Top Layer",
    bottomLayer: "Bottom Layer",
    bringForward: "Bring Forward",
    sendBackward: "Send backward",
    moveUp: "Up",
    down: "Down",
    leftShift: "Left Shift",
    rightShift: "Right Shift",
    flipHorizontal: "Flip Horizontal",
    flipVertical: "Flip Vertical",
    imageMatting: "Portrait cutout",
    petCutout: "Pet cutout",
    articleCutout: "Article Cutout",
    cutout1: "Cutout",
    replacePhoto: "Change Pic",
    replace: "Replace",
    loginFailure: "Login Failure",
    confirm: "CONFIRM",
    saveDrafts: "SAVE DRAFTS"
}