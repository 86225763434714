/*
* @author 友人a丶
* @date 2022-07-11
*
* 系统是否初始化成功
* */

import {defineStore} from "pinia";

export default defineStore('loaded', {
    state() {
        /*
        * 进入系统时需要判断用户有没有登录
        * 判断完毕，开始加载页面
        * */
        return {
            loaded: false
        }
    },
    actions: {
        /*
        * 系统加载完成
        * */
        load() {
            this.loaded = true;
        }
    }
})