/*
* @author 友人a丶
* @date
*
* */

import Action from "./action";
import {getAngle, getVector} from "../../utils/vector";

export default class Rotate extends Action {

    startPoint; //初始点

    /**
     *  图形移动
     * @param coords 位置数组
     * @returns {boolean}
     */
    move(coords) {

        /* 记录初始点位 */
        if (!this.startPoint) {
            this.startPoint = coords[0];
        }

        let {
            x,
            y,
            w,
            h
        } = this.shape.bounds(); //获取位置、长宽


        const rectCenterPoint = {
            x: x + w / 2,
            y: y + h / 2
        }; // 矩形中心点

        let angle = getAngle(getVector(rectCenterPoint, this.startPoint), getVector(rectCenterPoint, coords[0]));

        this.shape.rotate(angle, coords); //旋转
    }


    /**
     * 释放事件
     */
    up() {
        this.startPoint = null;
    }


}