/*加载动画相关的方法*/
import {
    closeToast,
    showConfirmDialog,
    showDialog,
    showFailToast,
    showLoadingToast,
    showSuccessToast,
    showToast
} from "vant";

/*
* @友人a丶
* 通用方法
* 封装的一些加载效果
* */

let index = null;
let timer = null;

/*页面加载的相关操作对象*/
export default {

    loading: function (msg) {

        /* 动态更新 */
        if (!index) {
            /* 显示加载弹窗 */
            index = showLoadingToast({
                message: msg,
                duration: 0,
                forbidClick: true,
            });
        } else {
            index.message = msg;
        }

    },

    /*关闭加载*/
    loaded: function (times = 500) {


        /* 双标 */
        /*try {
            throw new Error("记录调用栈");
        } catch (e) {
            console.log(e);
        }*/

        /* 如果存在 */
        if (timer) {
            clearTimeout(timer);
        }

        /*延时1s关闭弹窗*/
        timer = setTimeout(() => {
            closeToast();
            index = null;
            clearTimeout(timer);
        }, times)

    },

    /* 成功提示 */
    success: function (text) {
        showSuccessToast(text);
    },

    /* 成功提示 */
    fail: function (text) {
        showFailToast(text);
    },

    /* 轻提示 */
    toast(text) {
        showToast(text)
    },

    /* 点击确认后触发回调 */
    info(text, callback = null, config = {}) {
        closeToast();
        showDialog(Object.assign({
            message: text,
            allowHtml: true
        }, config)).then(() => {
            if (callback) {
                callback();
            }
        }).catch((e) => {
            console.log(e)
        });
    },

    /*消息提醒*/
    /*点击确认后触发回调*/
    error(text, callback = null) {
        closeToast();
        showDialog({
            message: text,
            title: "Tips",
            allowHtml: true
        }).then(() => {
            if (callback) {
                callback();
            }
        }).catch((e) => {
            console.log(e)
        });
    },

    /*点击确认后触发回调*/
    confirm(text, callback = null) {
        closeToast();
        showConfirmDialog({
            message: text,
            allowHtml: true
        }).then(() => {
            if (callback) {
                callback();
            }
        }).catch((e) => {
            console.log(e)
        });
    }

}


