/*引入vue-*/
import {createRouter, createWebHashHistory} from "vue-router"


/*
* meta元属性
*
* cache，代表页面是否缓存(true,缓存），默认不写为false
* icon，菜单的图标（andt内置图标或者自定义的iconfont）
* role，页面权限
* menu，是否作为菜单，默认不写为true
* name，默认的网页标题
* */

//路由页面
let routes = [
    {
        path: "/",
        meta: {
            name: "Againdiy",
        },
        component: () => import('@/pages/index'),
    },
    {
        path: "/login",
        meta: {
            name: "Againdiy",
        },
        component: () => import('@/pages/login'),
    },
    {
        path: "/demo",
        meta: {
            name: "演示",
        },
        component: () => import('@/pages/demo'),
    },
];

/*路由配置项*/
export const router = createRouter({
    history: createWebHashHistory(),
    routes
});

