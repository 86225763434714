/*
* 一些公共的方法
* */


/*
* 获取两个日期的所有中间日期
*
* start,end ,dayjs对象
* */
import dayjs, {isDayjs} from "dayjs";
import {isRef, reactive, inject, provide} from "vue";
import {cloneDeep} from "lodash";
import load from "@/common/load";
import { qrModalData } from "@/common/model"
import { initModel } from "@/pages/index/common";
import DIY from "@/diy/shape";
import { showDialog } from 'vant';

/**
 * 获取指定时间范围内的日期
 * @param start
 * @param end
 * @param format
 * @returns {*[]}
 */
export function getDays(start, end, format = "YYYY-MM-DD") {

    let days = [];
    let temp = start; //中间日期

    /*start大于等于end*/
    if (start.isAfter(end)) {
        return days;
    }

    /*相同那就是一天*/
    if (start.isSame(end)) {
        days.push(temp.format(format)); //默认第一天
    }

    /*遍历所有中间日期*/
    // eslint-disable-next-line
    while (1) {


        temp = temp.add(1, 'day'); //加1

        if (temp.isAfter(end)) {
            break;
        }

        days.push(temp.format(format));

    }


    return days;
}


/**
 * 延迟执行
 * @param callback
 * @param time
 */
export function delay(callback, time) {
    let timer = setTimeout(() => {
        clearTimeout(timer);
        callback();
    }, time);
}


/**
 * 禁止选择的时间范围
 * @param datetime
 * @returns {boolean}
 */
export function disabledDate(datetime) {
    let today = dayjs(); //当天的时间
    if (today.isAfter(dayjs(datetime))) {
        return false;
    } else {
        return true;
    }
}


/**
 * 返回表格行key值
 * @param record
 * @param key
 * @returns {*}
 */
export function key(record, key = "id") {
    return record[key];
}


/**
 * 获取上传文件的图片base64
 * @param file
 * @returns {Promise<unknown>}
 */
export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

/**
 * 获取间隔某个日期的天数
 * @param day
 * @returns {number}
 */
export function diff(day) {

    let now = dayjs().format("DD");

    if (parseInt(now) > parseInt(day)) {
        let predix = dayjs(dayjs().format("YYYY-MM-") + (parseInt(day) > 10 ? day : "0" + day)).add(1, "month");
        return predix.diff(dayjs(), 'day');
    } else {
        return parseInt(day) - parseInt(now);
    }
}


/*
* 条件转换
* */
export function switchForm(data) {

    //参数检测
    if (!data) {
        console.warn("无效参数");
        return {};
    }

    try {
        let condition;

        if (isRef(data)) {
            condition = cloneDeep(data.value);
        } else {
            condition = cloneDeep(data);
        }


        /*
        * 转换所有dayjs
        * */
        for (let i in condition) {

            if (condition[i] === "" || condition[i] === null || condition[i] === undefined) {
                delete condition[i];
                continue;
            }

            if (isDayjs(condition[i])) {
                condition[i] = condition[i].format("YYYY-MM-DD");
                continue;
            }

            if (isRef(condition[i])) {
                condition[i] = condition[i].value;
            }

            if (condition[i] instanceof Array) {

                if (condition[i].length == 0) {
                    delete condition[i];
                    continue;
                }

                let count = 0; //有效数据量

                condition[i] = condition[i].map(item => {
                    if (isDayjs(item)) {
                        item = item.format("YYYY-MM-DD");
                    }
                    if (item !== null) {
                        count++;
                    }
                    return item
                })

                if (count == 0) {
                    delete condition[i];
                }


                continue;
            }
        }


        return condition;
    } catch (e) {
        console.warn(e)
    }


}


/**
 * 数据容器，并提供重置的方法
 * @param ref
 * @returns {{data: *, reset: reset}}
 */
export function store(res) {

    let init = reactive({
        primary: cloneDeep(res),
        data: cloneDeep(res),
        /*
        * 重置回上一个快照
        * */
        reset: () => {
            init.data = Object.assign(init.data, init.primary);
        },
        /*
        * 替换
        * */
        $set(set) {
            init.data = Object.assign(init.data, set)
        },
        /*
        * 保存快照
        * */
        save: (snap) => {
            init.primary = cloneDeep(snap);
        }
    })

    return init;
}


/**
 * 下拉数据搜索
 * @param input
 * @param option
 * @returns {boolean}
 */
export function filterOptions(input, option) {
    return option.label.indexOf(input) > -1;
}


/**
 * @param arr
 * @returns {*}
 * 获取数组最后一个元素
 */
export function pop(arr) {
    if (arr.length === 0) {
        return null;
    }
    return arr[arr.length - 1];
}


/**
 * 获取请求参数
 * @returns {null|string}
 */
export function getQueryVariable() {
    const query = window.location.search.substring(1) || window.location.hash.split('?')[1] || ''; // 去掉问号
    const param = Object.create(null);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        param[pair[0]] = pair[1]; //参数
    }
    return param; // 如果没有找到，则返回 undefined
}


/**
 * 批量inject
 */
export function injects(keys) {
    const obj = Object.create(null);
    for (let i of keys) {
        obj[i] = inject(i, null);
    }
    return obj;
}

/**
 * 批量provide
 */
export function provides(obj) {
    for (let i in obj) {
        provide(i, obj[i])
    }
}


/*
* 休眠
* */
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}



/*
* 通过材质分类
* */
export function categoryGroupedArray(originalArray, materialData, colorData) {

    const data = originalArray.map(item => {
        // 根据材质key查询对应枚举字段
        const foundObject = materialData.find(obj => obj.dictValue === item.material);
  
        // 根据颜色key查询对应枚举字段
        const colorObject = colorData.find(obj => obj.dictValue === item.ex3);
        
        // 如果找到了对象，则重新赋值
        if (foundObject) {
          item.materialName = foundObject.dictLabel
          item.tags = foundObject.cssClass.split(/,|，/)
        }
  
        if (colorObject) {
          item.color = colorObject.dictValue
          item.colorName = colorObject.dictLabel
        }
        return item;
    });
    // 使用 reduce 方法对数组按照 category 字段进行分类

    const groupedArray = data.reduce((acc, currentItem) => {
        // 查找结果数组中是否已存在当前类型的数组
        const existingTypeArray = acc.find(typeArray => typeArray[0].material === currentItem.material);
      
        if (existingTypeArray) {
          // 如果当前类型已存在，将当前元素添加到该类型的数组中
          existingTypeArray.push(currentItem);
        } else {
          // 如果当前类型不存在，创建一个新的类型数组，并将当前元素添加到该数组中
          acc.push([currentItem]);
        }
      
        return acc;
    }, []);
    return groupedArray;
}

// webview 微信
export const isWebviewWechat = navigator && navigator.userAgent.toLocaleLowerCase().indexOf('miniprogram') > -1;

// 抖音小程序
export const isWebviewDouyin = navigator && navigator.userAgent.toLocaleLowerCase().indexOf('toutiaomicroapp') > -1;

// 引入对应jssdk
export function setJssdk() {
    console.log('[navigator]', navigator.userAgent)
    const color = 'color: red;font-size: 20px'
    if (isWebviewWechat) {
      console.log('%c 当前环境：webview微信', color)
      appendJS('https://res.wx.qq.com/open/js/jweixin-1.3.2.js')
    }else if(isWebviewDouyin) {
        appendJS('https://lf1-cdn-tos.bytegoofy.com/goofy/developer/jssdk/jssdk-1.1.0.js')
    }
}

export function navigateTo(url) {
    // 微信跳转
    if (isWebviewWechat) {
      // eslint-disable-next-line no-undef
      wx.miniProgram.getEnv(function (res) {
        console.log('[wx.miniProgram getEnv]', res.miniprogram) // true
        if (res.miniprogram) {
          // eslint-disable-next-line no-undef
          wx.miniProgram.navigateTo({
            url: url,
            fail: (e) => {
                console.log('redirectTo执行失败：', e);
            },
            success: (e) => {
                console.log('redirectTo执行成功：', e);
            }
          })
        }
      })
    }else if(isWebviewDouyin){
        // eslint-disable-next-line no-undef
        tt.miniProgram.postMessage({
            data: {
              mes: "postMessage1",
            },
            // success 回调表示把 data 数据存储成功。
            // 存储成功的数据会在小程序后退、组件销毁、页面分享时，通过 bindmessage 发送给用户
            success() {
              console.log("网页消息");
            },
            // fail 回调表示没有把 data 数据存储成功
            fail(err) {
              console.log(err);
            },
        });
        showDialog({
            title: '定制成功',
            message: '1.您的定制已完成，如需修改请联系客服。\n2.定制商品无质量问题不支持退换货申请。',
            theme: 'round-button',
            messageAlign: 'left',
            cancelButtonText: '我知道了',
            className: 'showDialog'
        });
    }
}

export function appendJS(url) {
    var head = document.head || document.getElementsByTagName('head')[0]
    var script = document.createElement('script')
    script.setAttribute('src', url)
    head.appendChild(script)
}

export const imgUrlToBase64 = url => {
    return new Promise((resolve, reject) => {
      if (!url) {
        reject('请传入url内容')
      }
  
      if (/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(url)) {
        // 图片地址
        const image = new Image()
        // 设置跨域问题
        image.setAttribute('crossOrigin', 'anonymous')
        // 图片地址
        image.src = url
        image.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = image.width
          canvas.height = image.height
          ctx.drawImage(image, 0, 0, image.width, image.height)
          // 获取图片后缀
          const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase()
          // 转base64
          const dataUrl = canvas.toDataURL(`image/${ext}`)
          resolve(dataUrl || '')
        }
      } else {
        // 非图片地址
        reject('非(png/jpe?g/gif/svg等)图片地址')
      }
    })
}

export const changeModel = (i18n, data, selectedData, global)=> {
    return new Promise(resolve=> {
        load.loading(i18n('loading'));
        const json = qrModalData(selectedData.class_id);

        let fore_json = null;
        let back_json = null;

        if(global.fore.snap) {
            fore_json = global.fore.snap.fore;
        }
        if(global.back.snap) {
            back_json = global.back.snap.fore;
        }
        // 替换正面背景图片和区域图
        json[0].back[0].url = data.ex4;
        json[0].diy.url = data.ex1;

        if(json.length > 1) {
            json[1].back[0].url = data.ex9;
            json[1].diy.url = data.ex7;
        }

        initModel(json, {fore: false}).then(()=> {
            // 记录颜色和skuId
            selectedData.color = data.color;
            selectedData.colorName = data.colorName;
            selectedData.skuId = data.id;
            selectedData.materialName = data.materialName;

            if(fore_json) {
                global.fore.snap.fore = fore_json;
            }
            if(back_json) {
                global.back.snap.fore = back_json;
            }
    
            // useSkuStore().setSelectedData(selectedData)
            load.loaded();
            resolve();
        }); //初始化模型

    })
}

export const imgWebp = (url)=> {
    return `${url}?x-oss-process=image/resize,h_370/format,webp`
}

export const groupToJson = async (global) => {
    /* 停止渲染 */
    global.stage.stop();
    let diyJson = {}
    
    if (global.back.json && global.back.snap.fore.length > 0 && global.fore.snap) {
        
        /* 处理背面数据 */
        if (global.active !== "back") {
            /* 记录快照 */
            global.active = "back";
            global.fore.snap = global.stage.snap(true);
            await global.stage.alter(global.back.snap, true);
        }
        
        
        /* 打散所有分组 */
        for (let i of global.stage.shapes(true)) {
            if (i.type === "Group") {
          i.unbind();
        }
      }
  
  
      /* 编组所有图形 */
      const back_group = await global.stage.add(new DIY.Group({
        name: "编组",
        shapes: global.stage.shapes(true)
      }));
  
      back_group.rule = 'unbind'; //导入自动解绑
      back_group.fit = false; //自适应
  
      diyJson.back_json = back_group.toJson({
        element: false, //导出绑定的DOM
        private: false, //私有属性
        relative: true //装换为相对单位
      });
  
      /* 打散图形 */
      back_group.unbind();
    }
  
    
    /* 激活正面图形 */
    if (global.active !== "fore") {
        /* 记录快照 */
        global.active = "fore";
        global.back.snap = global.stage.snap(true);
        await global.stage.alter(global.fore.snap, true);
    }
    
    
    /* 打散所有编组 */
    for (let i of global.stage.shapes(true)) {
        if (i.type === "Group") {
            i.unbind();
        }
    }
    
    /* 将所有图形编组 */
    const fore_group = await global.stage.add(new DIY.Group({
        name: "编组",
        shapes: global.stage.shapes(true)
    }));
    
    fore_group.rule = 'unbind'; //渲染后自动解绑
    fore_group.fit = true; //自适应
    
    /* 导出Json */
    diyJson.fore_json = fore_group.toJson({
        element: false, //导出绑定的DOM
        private: false, //私有属性
        relative: true //装换为相对单位
    });
    
    /* 取消编组 */
    fore_group.unbind();
    /* 恢复渲染 */
    global.stage.resume();
  
    return diyJson;
}

/* 加载图形 */
export const loadGroup = async (global, group_json) => {

    /* 待加载的模型 */
    const models = [];

    /* 修改正面json */
    global.fore.json.fore = [group_json.fore_json];
    models.push(global.fore.json); //新增

    /* 如果存在方面。并且当前模型也有反面 */
    if (group_json.back_json) {
        global.back.json.fore = [group_json.back_json];
        models.push(global.back.json); //新增
    }

    /* 重新初始化模型 */
    await initModel(models);
    /* 关闭面板 */
    load.loaded();
}

// json中文替换
export const replaceText = (str) => {
    // 要替换的多个字符
    let replacements = {
      "替换": "Replace",
      "编辑": "Edit",
      "https://assets.daiwing.com": "https://shopfiy-us.oss-accelerate.aliyuncs.com"
    };
  
    // 使用正则表达式和 replace 方法替换多个字符
    for (let search in replacements) {
      if (replacements.hasOwnProperty(search)) {
        let regex = new RegExp(search, "g");
        str = str.replace(regex, replacements[search]);
      }
    }
    return str;
};

// 路由参数转对象
export const getParamsObject = ()=> {
    // 获取完整的URL
    const fullURL = window.location.href;

    // 按照 # 分割URL成两部分
    const parts = fullURL.split('#');

    // 解析第二部分（包含 ? 的部分）
    const queryString = parts.length > 1 ? parts[1].split('?')[1] : '';

    // 创建 URLSearchParams 对象
    const urlParams = new URLSearchParams(queryString);

    // 创建一个空对象，用于存储查询参数
    const paramsObject = {};

    // 遍历 URLSearchParams 中的每一对键值对，并添加到 paramsObject 中
    for (const [key, value] of urlParams.entries()) {
    paramsObject[key] = value;
    }
    return paramsObject
}

// img转webp
export const imgToWebp = (arr)=> {
    if(!Array.isArray(arr)) return arr;

    arr.shapes = arr.map(item => {
        if (item.type === "Image") {
          item.url = item.url.indexOf('?x-oss-process') > -1 ? item.url : item.url + '?x-oss-process=image/resize,h_1200/format,webp';
        }
        return item;
    });
    return arr;
}