/*
* @author 友人a丶
* @date 
* 
* */

import Shape from "../../proto/shape";
import getVectorCenter from "../../utils/vector";
import {percentToValue} from "../../utils/math";


export default class Text extends Shape {

    /* 构造函数 */
    constructor(config = {}) {

        /* 初始化父类构造函数 */
        super(Object.assign({

            /* 图形的 */
            x: null, //原点x坐标
            y: null, //原点y坐标,

            button_2_Text: "Edit",
            canDo: true, //是否允许更好文字
            canFlip: true, //是否允许翻转图片

            /* 包围盒的边界范围 */
            _bounds: {
                x: null, //原点x坐标
                y: null, //原点y坐标,
                w: null, //文字所占的宽
                h: null, //文字所占的高
            },

            // 翻转类型
            flip: {
                x: 1,
                y: 1
            },

            /* 最大宽高 */
            maxWidth: 50,
            maxHeight: 50,

            text: "",
            fontSize: 16,
            fontFamily: "Arial",
            textColor: "rgba(54,54,54,0.9)",

            bold: false,
            italic: false,
            textDecoration: false,


            textBaseline: "middle",
            textAlign: "center",
            background: true,
            cornerRadius: 5,
            backgroundColor: "transparent",
            border: 0,
            borderColor: "#000000",
            padding: {
                top: 6,
                bottom: 6,
                left: 6,
                right: 6,
            },
            direction: "horizontal",
            maxLineWidth: null,
            letterSpacing: 0,
            lineSpacing: 0,
            spacingLeft: 1,
            spacingTop: 1,
            type: "Text" //图形类型
        }, config));


    }


    /**
     * 添加到画布后进行初始化
     */
    _init() {

        /* 已经添加到上下文 */
        if (this._context) {

            const bounds = this.getReference();

            /* 如果有百分数，转换为数值 */
            if (this.x && (typeof this.x) === 'string') {
                this.x = percentToValue(this.x, bounds._w) + bounds._x;
            }

            if (this.y && (typeof this.y) === 'string') {
                this.y = percentToValue(this.y, bounds._h) + bounds._y;
            }

            if (this.fontSize && (typeof this.fontSize) === 'string') {
                this.fontSize = percentToValue(this.fontSize, bounds._h);
            }

            /* 初始化默认坐标 */
            this.x = this.x !== null ? this.x : (bounds._w / 2 + bounds._x);
            this.y = this.y !== null ? this.y : (bounds._h / 2 + bounds._y);

            /* 加载非默认字体时触发 */
            if (this.fontFamily !== 'Arial') {
                /* 触发字体加载事件 */
                this.stage().emit('use-font', {
                    type: 'use-font',
                    shape: this,
                    fontFamily: this.fontFamily
                })
            }

        }


        /* 节流函数 */
        const that = this;

        /**
         * 输入文字
         */
        this.input = (text) => {
            that.text = text;
            that.render();
        };

        /**
         * 追加文字
         */
        this.append = (text) => {
            that.text += text;
            that.render();
        };

    }


    /**
     * 更换字体
     */
    setFont(font) {
        this.fontFamily = font;
        this.render();
    }


    /**
     * 获取翻转类型
     * @return {number}
     */
    getFlip() {
        if (!this.canFlip) {
            return 0; //没翻转
        } else if (this.flip.x < 0 && this.flip.y > 0) {
            return 1; //只有水平翻转
        } else if (this.flip.x > 0 && this.flip.y < 0) {
            return 2; //只有垂直翻转
        } else if (this.flip.x < 0 && this.flip.y < 0) {
            return 3; //垂直+水平同时翻转
        } else {
            return 0;
        }
    }


    /* 水平翻转和垂直翻转 */
    _flip() {
        /* 是否可以翻转 */
        if (this._context && this.canFlip) {
            this._context.scale(this.flip.x, this.flip.y);
        }
        /* 返回当前的翻转类型 */
        return this.getFlip();
    }


    /**
     * 获取翻转之后的坐标和范围
     * @private
     */
    _getFlip() {

        /* 获取翻转类型 */
        const flip = this.getFlip();
        const {x, y, w, h} = this._bounds;

        /* 绘制图片的路径信息，用户判断是否被点击 */
        if (flip === 0) {
            return {
                _x: x,
                _y: y
            }
        } else if (flip === 1) {
            return {
                _x: -x - w,
                _y: y
            }
        } else if (flip === 2) {
            return {
                _x: x,
                _y: -y - h
            }
        } else if (flip === 3) {
            return {
                _x: -x - w,
                _y: -y - h
            }
        }

    }


    /**
     * 获取翻转之后的坐标和范围
     * @private
     */
    _getFlipText() {

        /* 获取翻转类型 */
        const flip = this.getFlip();
        /* 边界范围 */
        const {x, y} = this;

        /* 绘制图片的路径信息，用户判断是否被点击 */
        if (flip === 0) {
            return {
                _x: x,
                _y: y
            }
        } else if (flip === 1) {
            return {
                _x: -x,
                _y: y
            }
        } else if (flip === 2) {
            return {
                _x: x,
                _y: -y
            }
        } else if (flip === 3) {
            return {
                _x: -x,
                _y: -y
            }
        }

    }


    /**
     * 绘制图形路径
     * @private
     */
    _draw() {

        /** @type {CanvasRenderingContext2D} */
        const context = this._context;

        let {
            x,
            y,
            w,
            h
        } = this._bounds;

        context.beginPath();

        this._rotate(); //旋转

        /* 绘制图片的路径信息，用户判断是否被点击 */
        context.rect(x, y, w, h);
        context.closePath();
    }


    /**
     * 处理旋转
     */
    _rotate() {

        /** @type {CanvasRenderingContext2D} */
        const context = this.getContext(); //上下文

        let {
            x, y
        } = !!this._group ? this._group.getCenterPoint() : this.getCenterPoint();

        /* 是否需要旋转 */
        if (this.rotateRadius !== 0) {
            context.translate(x, y);
            context.rotate(this.rotateRadius); //旋转
            context.translate(-x, -y);
        }

    }


    /**
     * 图形缩放，围绕中心点缩放
     * @param zoom 缩放的比例
     * @param coords 触摸点
     */
    scale(zoom, coords) {


        /* 记录初始大小 */
        if (!this._startBounds) {

            /* 中心点 */
            const center = this.stage().getLimit() && coords.length > 1 ? getVectorCenter(coords[0], coords[1]) : (!!this._group ? this._group.getCenterPoint() : this.getCenterPoint())

            /* 初始边界范围 */
            this._startBounds = {
                x: this.x,
                y: this.y,
                centerX: center.x,
                centerY: center.y,
                fontSize: this.fontSize
            };
        }

        /* 根据缩放比例重新计算位置和大小 */
        const {x, y, centerX, centerY, fontSize} = this._startBounds;

        this.x = Math.floor(centerX - (centerX - x) * zoom);
        this.y = Math.floor(centerY - (centerY - y) * zoom);

        this.fontSize = fontSize * zoom;

        /* 触发事件 */
        this.emit("scale", {type: 'scale', coords, zoom, shape: this});
        this.stage().emit("scale", {type: 'scale', coords, zoom, shape: this});

        /* 渲染 */
        this.render();
        return true;
    }


    /**
     * 更新边界范围
     */
    updateBounds() {
        /* 计算边界范围 */
        this._bounds = this._context.textBounds(this);
    }


    /**
     *  图形绘制
     *  @param flag 绘制选择状态
     */
    draw(flag = true) {

        /* 离屏还是绘制 */
        /** @type {CanvasRenderingContext2D} */
        const context = this._context; //上下文

        /* 重新开始新的路径 */
        context.beginPath();

        /* 保存状态 */
        context.save();
        /* 混合模式 */
        context.globalCompositeOperation = this.blendMode;

        /* 计算边界范围 */
        this._bounds = context.textBounds(this);

        this._rotate(); //旋转
        this._flip(); //翻转

        const {_x, _y} = this._getFlipText();

        /* 绘制，并更新边界范围 */
        context.drawText(Object.assign(this.getConfig(), {
            x: _x,
            y: _y
        }));


        /* 删除线 */
        if (this.textDecoration) {

            /* 边界范围 */
            let {w, h} = this.bounds();
            let {_x, _y} = this._getFlip();


            /* 路径 */
            context.beginPath();
            context.strokeStyle = this.textColor;
            context.moveTo(_x + this.padding.left, _y + h - this.padding.bottom);
            context.lineTo(_x + w - this.padding.right, _y + h - this.padding.bottom);
            context.closePath();
            context.stroke();
        }


        /* 如果被编组，并且编组被激活 */
        if (this._group && this._group.actived) {

            /* 开始路径 */
            context.beginPath();

            /* 获取配置 */
            const config = this.stage().getConfig();

            /* 边界范围 */
            let {w, h} = this.bounds();
            let {_x, _y} = this._getFlip();


            /* 设置绘制参数 */
            context.strokeStyle = config.activeColor;
            context.setLineDash([2, 3]);
            context.lineWidth = 1;
            context.lineCap = "round";
            context.lineJoin = "round";

            /* 选择框的间隙 */
            const gap = 2;
            context.strokeRect(_x - gap, _y - gap, w + gap * 2, h + gap * 2);
            context.closePath();
        }


        /* 关闭路径 */

        context.restore(); //恢复状态


        // 如果被选中则绘制被选中的效果
        // 离屏渲染不绘制
        // 是否选择、裁剪、离屏
        // 选择状态的反选
        if (flag && this.actived) {
            const that = this; //对象
            /* 判端选择状态是即时绘制还是队列处理 */
            if (this.queue) {
                this.stage().addQueueTask(() => {
                    that._action.draw(); //绘制选中状态
                })
            } else {
                that._action.draw(); //绘制选中状态
            }
        }


    }


    /**
     * 获取图形边界范围的坐标
     * @param corner
     * @returns {{x: (number|string|*), y: (number|string|*)}|{x: *, y: (number|string|*)}|{x: (number|string|*), y: *}|{x: *, y: *}|[{x: (number|string|*), y: (number|string|*)},{x: *, y: (number|string|*)},{x: (number|string|*), y: *},{x: *, y: *}]}
     */
    coords(corner = null) {

        const {
            x, y, w, h
        } = this._bounds;

        const coords = [
            {x: x, y: y},
            {x: x + w, y: y},
            {x: x, y: y + h},
            {x: x + w, y: y + h},
            {x: x + (w / 2), y: y},
            {x: x + w, y: y + (h / 2)},
            {x: x, y: y + (h / 2)},
            {x: x + (w / 2), y: y + (h / 2)}
        ]

        if (corner === null) {
            return coords;
        } else {
            return coords[corner];
        }

    }


    /*
    * 获取边界范围
    * */
    bounds() {
        return this._bounds;
    }


    /**
     * 获取文本
     */
    getText() {
        return this.text;
    }


    /**
     * 相对位置
     */
    relative() {

        /* 舞台的边界范围 */
        const bounds = this.getReference();

        return {
            x: (((this.x - bounds._x) / bounds._w) * 100).toFixed(2) + "%",
            y: (((this.y - bounds._y) / bounds._h) * 100).toFixed(2) + "%",
            maxWidth: ((this.maxWidth / bounds._w) * 100).toFixed(2) + "%",
            maxHeight: ((this.maxHeight / bounds._h) * 100).toFixed(2) + "%",
            fontSize: ((this.fontSize / bounds._h) * 100).toFixed(2) + "%",
        }

    }


    /**
     * 获取配置
     */
    getConfig() {

        /* 默认配置 */
        const config = {
            text: "",
            fontSize: 16,
            bold: false,
            italic: false,
            textDecoration: false,
            fontFamily: "Arial",
            textColor: "#ffffff",
            textAlign: "center",
            background: true,
            cornerRadius: 5,
            backgroundColor: "transparent",
            border: 0,
            borderColor: "#000000",
            padding: {
                top: 6,
                bottom: 6,
                left: 6,
                right: 6,
            },
            direction: "horizontal",
            maxLineWidth: null,
            letterSpacing: 0,
            lineSpacing: 0,
            x: 0,
            y: 0,
            _bounds: null,
            spacingLeft: 1,
            spacingTop: 1
        };

        /* 输出配置 */
        for (let i in config) {
            if (!!this[i]) {
                config[i] = this[i];
            }
        }


        return config;
    }


}