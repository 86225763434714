/*
* @author 友人a丶
* @date 
* 组件公共数据
* */
import {shallowReactive} from "vue";

/* DIY面板公共数据 */
const global = shallowReactive({
    /* 舞台对象 */
    stage: null,
    /* 正面的配置 */
    fore: {
        json: null,
        snap: null
    },
    /* 反面的快照 */
    back: {
        json: null,
        snap: null
    },
    /* 被激活的是哪一面 */
    active: 'fore',
    /* 是否有反面 */
    hasBack: false
});

window.global = global;
export default global;