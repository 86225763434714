/**
 * @author 爱心发电丶
 * @date 2023-10-17
 * 一些向量相关的函数
 */
import {toFixed} from "./math";

/**
 * 获取两个点的向量
 * @param p1
 * @param p2
 * @returns {{x: number, y: number}}
 */
export function getVector(p1, p2) {
    let x = toFixed(p1.x - p2.x, 5)
    let y = toFixed(p1.y - p2.y, 5);
    return {
        x,
        y
    };
}


/**
 * 获取两个向量的中心点
 * @param p1
 * @param p2
 * @returns {{x: number, y: number}}
 */
export default function getVectorCenter(p1, p2) {
    const centerX = (p1.x + p2.x) / 2;
    const centerY = (p1.y + p2.y) / 2;
    return {x: centerX, y: centerY};
}


/**
 * 获取某个向量的模
 * @param v1
 * @returns {number}
 */
export function getLength(v1) {
    return Math.sqrt(v1.x * v1.x + v1.y * v1.y);
}


/**
 * 获取向量的夹角
 * 判断方向，顺时针为 1 ，逆时针为 -1;
 * @param v1
 * @param v2
 * @returns {number}
 */
export function getAngle(v1, v2) {

    /* 通过坐标位置判断方向 */
    let direction = v1.x * v2.y - v2.x * v1.y > 0 ? 1 : -1;

    let len1 = getLength(v1);
    let len2 = getLength(v2);
    let mr = len1 * len2;
    let dot, r;

    if (mr === 0) return 0;

    // 通过数量积公式可以推导出:
    // cos =(x1 *x2 +y1*y2)/(lal * Ib1);

    dot = v1.x * v2.x + v1.y * v2.y;
    r = dot / mr;

    if (r > 1) r = 1;
    if (r < -1) r = -1;

    // 解值并结合方向转化为角度值;
    // 180 / Math.PI
    return Math.acos(r) * direction;

}


/**
 * 弧度转角度
 * @param radians
 * @returns {number}
 */
export function radToDegree(radians) {
    return radians * 180 / Math.PI;
}


/**
 * 向量围绕指定坐标旋转之后的坐标
 * @param vector 需要计算的坐标点
 * @param angle 角度
 * @param pivot 参照的坐标
 * @returns Object
 */
export function rotateVector(vector, angle, pivot) {

    /* 将向量平移到原点 */
    const x1 = vector.x - pivot.x;
    const y1 = vector.y - pivot.y;

    /* 转换成弧度 */
    const rad = angle * Math.PI / 180;

    /* 计算旋转后的坐标 */
    const x2 = x1 * Math.cos(rad) - y1 * Math.sin(rad);
    const y2 = x1 * Math.sin(rad) + y1 * Math.cos(rad);

    /* 将向量平移到原来位置 */
    return {x: toFixed(x2 + pivot.x, 3), y: toFixed(y2 + pivot.y, 3)};
}


/**
 * 计算旋转前的坐标
 * @param {Object} vector - 旋转后的坐标，包含 x 和 y 属性
 * @param {number} angle - 旋转角度，单位为度
 * @param {Object} pivot - 旋转中心点，包含 x 和 y 属性
 * @returns {Object} 旋转前的坐标，包含 x 和 y 属性
 */
export function reverseRotatePoint(vector, angle, pivot) {
    // 将向量平移到原点
    const x1 = vector.x - pivot.x;
    const y1 = vector.y - pivot.y;

    // 将旋转角度转换为弧度
    const rad = -angle * Math.PI / 180;

    // 计算旋转前的坐标
    const x2 = x1 * Math.cos(rad) - y1 * Math.sin(rad);
    const y2 = x1 * Math.sin(rad) + y1 * Math.cos(rad);

    // 将向量平移到原来位置
    return {x: toFixed(x2 + pivot.x, 3), y: toFixed(y2 + pivot.y, 3)};
}


/**
 * 计算指定坐标数组中的最左右上下的坐标值
 * @param rectangles
 * @returns {*}
 */
export function getBoundingBox(rectangles) {

    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;

    rectangles.forEach(function (rectangle) {
        minX = Math.min(minX, rectangle.x);
        minY = Math.min(minY, rectangle.y);
        maxX = Math.max(maxX, rectangle.x + rectangle.w);
        maxY = Math.max(maxY, rectangle.y + rectangle.h);
    });

    return {
        x: minX,
        y: minY,
        w: (maxX - minX),
        h: (maxY - minY)
    }
}


/**
 * 计算指定坐标数组中的最左右上下的坐标值
 * @returns {*}
 * @param coords
 */
export function getCoordsBox(coords) {

    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;

    coords.forEach(function (coord) {
        minX = Math.min(minX, coord.x);
        minY = Math.min(minY, coord.y);
        maxX = Math.max(maxX, coord.x);
        maxY = Math.max(maxY, coord.y);
    });

    return {
        x: minX,
        y: minY,
        w: (maxX - minX),
        h: (maxY - minY)
    }
}


/**
 * 放大后的坐标向量
 * @param v1
 * @param s
 * @returns {number}
 */
export function scaleVector(v1, s) {
    return {
        x: v1.x * s,
        y: v1.y * s
    }
}

