/*
* @author 友人a丶
* @date 
* 
* */

import Shape from "../../proto/shape";
import {percentToValue} from "../../utils/math";
import {loadImage} from "../../utils/common";


/**
 * 关于图片对象
 * init，实例化的时候开始加载图片
 * 所有图形只会新增到舞台时，会触发一次init
 */

export default class Image extends Shape {

    /* 构造函数 */
    constructor(config = {}) {

        /* 初始化父类构造函数 */
        super(Object.assign({
                x: null, //原点x坐标
                y: null, //原点y坐标
                w: null, //绘制的长宽
                h: null, //绘制的长宽
                url: null, //图像的src属性
                originWidth: null, //原图的宽度
                originHeight: null, //原图的高度
                image: null, //图形的图像
                type: "Image", //图形类型
                button_2_Text: "replace",
                canDo: true, //是否响应额外动作
                canFlip: true, //是否允许翻转图片
                // 翻转类型
                flip: {
                    x: 1,
                    y: 1
                }
            },
            config
        ))
        ;


        /* 初始化图形 */
        this._initImage(config);
    }

    /**
     * 初始化图形对象
     * @private
     */
    _initImage(config) {

        /* 直接指定的Image对象 */
        if (config.image) {
            /* 更新image */
            this.image = config.image;
            /* 保存图片的src地址 */
            this.url = config.image.src;
            /* 中断 */
            return;
        }


        /* 如果指定的url属性 */
        if (config.url) {

            this.url = config.url; //更新链接

            loadImage(config.url, (image) => {
                /* 记录image */
                this.image = image;

                /* 重置图片的大小参数 */
                this.originWidth = null;
                this.originHeight = null;

                /* 触发图片加载完毕事件 */
                this.emit("loaded", {type: 'loaded', shape: this});

            }, () => {
                this.destroy();
            })


        }
    }


    /**
     * 未指定坐标时，计算初始坐标
     * 替换Image对象后，更新图片大小
     * @private
     */
    _initCoords() {

        /* 获取图片的长宽 */
        let {
            width,
            height
        } = this.image;


        /* 保存原始属性 */
        this.originWidth = width;
        this.originHeight = height;

        /* 原点坐标已经确定了，代表只需要更新长宽 */


        /**
         * 没有被指定值的属性才会重新计算
         */

        /* 画布的边界范围 */
        const bounds = this.getReference();
        /* 获取配置 */
        const config = this.stage().getConfig();


        /* 参照的大小 */
        let maxWidth = width < config.maxWidth ? width : config.maxWidth;
        let maxHeight = height < config.maxHeight ? height : config.maxHeight;


        /* 如果长宽都没有 */
        if (this.w === null && this.h === null) {
            /* 如果宽大于高 */
            if (bounds._w <= bounds._h) {
                this.w = maxWidth;
                this.h = this.w * (height / width);
            } else {
                this.h = maxHeight;
                this.w = (this.h * (width / height));
            }
        }


        /* 如果有长没宽 */
        if (this.w === null && this.h) {
            this.w = (this.h * (width / height));
        }

        /* 如果有宽没长 */
        if (this.w && this.h === null) {
            this.h = this.w * (height / width);
        }


        /* 计算图片坐标 */
        this.x = this.x !== null ? this.x : ((bounds._w - this.w) / 2 + bounds._x);
        this.y = this.y !== null ? this.y : ((bounds._h - this.h) / 2 + bounds._y);


        /* 如果需要绘制外边界 */
        if (!this._colorBounds && this.outline) {
            this.initColorBounds();
        }


    }


    /**
     * 替换图形对象后更新坐标
     * @private
     */
    _updateCoords() {

        /* 获取图片的长宽 */
        let {
            width,
            height
        } = this.image;


        /* 保存原始属性 */
        this.originWidth = width;
        this.originHeight = height;

        /* 宽度大于长度 */
        if (this.w > this.h) {
            this.h = this.w * (height / width);
        } else {
            this.w = this.h * (width / height);
        }


        /* 如果需要绘制外边界，刷新边界 */
        if (this.outline) {
            this.initColorBounds();
        }


    }

    /**
     * 添加到画布后进行初始化
     * 替换Image对象后重新初始化
     */
    _init() {

        const that = this;

        /* 已经添加到舞台了，转换百分比参数 */
        if (that._context) {
            /* 获取参照区域的边界 */
            const bounds = that.getReference();
            /* 如果有百分数，转换为数值 */
            if (that.x && (typeof this.x) === 'string') {
                that.x = percentToValue(this.x, bounds._w) + bounds._x;
            }
            if (that.y && (typeof this.y) === 'string') {
                that.y = percentToValue(this.y, bounds._h) + bounds._y;
            }
            if (that.w && (typeof this.w) === 'string') {
                that.w = percentToValue(this.w, bounds._w);
            }
            if (that.h && (typeof this.h) === 'string') {
                that.h = percentToValue(this.h, bounds._h);
            }
        }

        return new Promise(resolve => {


            /* 已经添加到上下文 */
            /* 正常加载 */
            if (this._context && that.image) {

                /* 是否要初始化坐标 */
                /* 初始化图形、替换图片时才会触发 */
                if (!that.originWidth || !that.originHeight) {
                    that._initCoords();
                }

                that.render(); //刷新画布
                resolve(); //跳出Promise

            } else {
                /* 这边图片还没加载完毕，则需要等待图片初始化完毕，重新触发_init */
                that.on("loaded", () => {
                    that._init();//重新初始化
                    that.removeAlllistener("loaded");
                    resolve();//跳出Promise
                }, true); //不重复触发
            }
        })
    }


    /**
     * 获取翻转类型
     * @return {number}
     */
    getFlip() {
        if (!this.canFlip) {
            return 0;
        } else if (this.flip.x < 0 && this.flip.y > 0) {
            return 1;
        } else if (this.flip.x > 0 && this.flip.y < 0) {
            return 2;
        } else if (this.flip.x < 0 && this.flip.y < 0) {
            return 3;
        } else {
            return 0;
        }
    }


    /* 水平翻转和垂直翻转 */
    _flip() {
        /* 是否可以翻转 */
        if (this._context && this.canFlip) {
            this._context.scale(this.flip.x, this.flip.y);
        }
        /* 返回当前的翻转类型 */
        return this.getFlip();
    }


    /**
     * 获取翻转之后的坐标和范围
     * @private
     */
    _getFlip() {

        /* 获取翻转类型 */
        const flip = this.getFlip();
        const {x, y, w, h} = this.bounds();

        /* 绘制图片的路径信息，用户判断是否被点击 */
        if (flip === 0) {
            return {
                _x: x,
                _y: y
            }
        } else if (flip === 1) {
            return {
                _x: -x - w,
                _y: y
            }
        } else if (flip === 2) {
            return {
                _x: x,
                _y: -y - h
            }
        } else if (flip === 3) {
            return {
                _x: -x - w,
                _y: -y - h
            }
        }

    }


    /**
     * 绘制图形路径
     * @private
     */
    _draw() {

        /* 图片对象是否存在 */
        if (!this.image) return;

        /** @type {CanvasRenderingContext2D} */
        const context = this._context;

        let {
            x,
            y,
            w,
            h
        } = this.bounds(); //获取位置、长宽

        context.beginPath();
        this._rotate(); //旋转

        context.rect(x, y, w, h);
        context.closePath();
    }


    /**
     * 处理旋转
     */
    _rotate(ctx = null) {

        let {
            x,
            y,
            w,
            h
        } = this;


        /** @type {CanvasRenderingContext2D} */
        const context = ctx ? ctx : this.getContext(); //上下文

        /* 是否围绕中心点旋转 */
        const rectCenterPoint = !!this._group ? this._group.getCenterPoint() : (this.rotateAroundCenter ? {
            x: x + w / 2,
            y: y + h / 2
        } : {x: this.x, y: this.y}); // 矩形中心点

        /* 是否需要旋转 */
        if (this.rotateRadius !== 0) {
            context.translate(rectCenterPoint.x, rectCenterPoint.y);
            context.rotate(this.rotateRadius); //旋转
            context.translate(-rectCenterPoint.x, -rectCenterPoint.y);
        }

    }

    /**
     * 更新图片
     */
    update(config) {
        /* 如果可以替换 */
        if (!this.canDo) return;

        /* 这边图片还没加载完毕，则需要等待图片初始化完毕，重新触发_init */
        this.on("loaded", () => {
            this._updateCoords();//重新初始化
            this.removeAlllistener("loaded");
            this.render(); //刷新
        }, true); //不重复触发

        /* 刷新图形 */
        this._initImage(config);
        /* 重新渲染 */
        this.render();
    }


    /**
     * 绘制外边界
     */
    stokeOutline() {


        /* 是否需要绘制外边界 */
        if (!this.outline) return;

        /* 离屏还是绘制 */
        /** @type {CanvasRenderingContext2D} */
        const context = this._context; //上下文

        /* 重新开始新的路径 */
        context.beginPath();
        /* 保存状态 */
        context.save();

        /* 旋转 */
        this._rotate();
        this._flip(); //翻转

        this._stokeOutline();

        /* 关闭路径 */
        context.closePath();
        /* 恢复状态 */
        context.restore();


    }

    /**
     * 绘制外边界
     */
    _stokeOutline() {

        /* 离屏还是绘制 */
        /** @type {CanvasRenderingContext2D} */
        const context = this._context; //上下文


        /* 没有初始化过边界 */
        if (!this._colorBounds) {
            this.initColorBounds();
        }

        /* 是否允许边界绘制 */
        if (this.canShowOutline()) {
            /* 混合模式 */
            context.globalCompositeOperation = "source-over";
            context.strokeStyle = this.strokeStyle;
            context.setLineDash([2, 3]);
            context.lineWidth = 1;
            context.lineCap = "round";
            context.lineJoin = "round";
            context.strokeRect(this._colorBounds._x + 1, this._colorBounds._y + 1, this._colorBounds._w - 2, this._colorBounds._h - 2);
        }
    }

    /**
     *  图形绘制
     *  @param flag 绘制选择状态
     */
    draw(flag = true) {

        /* 图片对象是否存在 */
        if (!this.image) return;

        /* 离屏还是绘制 */
        /** @type {CanvasRenderingContext2D} */
        const context = this._context; //上下文


        let {
            w,
            h
        } = this;

        /* 重新开始新的路径 */
        context.beginPath();
        /* 保存状态 */
        context.save();

        /* 旋转 */
        this._rotate();
        this._flip(); //翻转

        /* 混合模式 */
        context.globalCompositeOperation = this.blendMode;

        /* 获取翻转转换之后的坐标 */
        const {_x, _y} = this._getFlip();
        context.drawImage(this.image, _x, _y, w, h);


        /* 如果需要绘制边界 */
        if (this.outline && flag) {
            this._stokeOutline();
        }


        /* 关闭路径 */
        context.closePath();
        /* 恢复状态 */
        context.restore();


        // 如果被选中则绘制被选中的效果
        // 离屏渲染不绘制
        // 是否选择、裁剪、离屏
        // 选择状态的反选
        if (flag && this.actived) {
            const that = this; //对象
            /* 判端选择状态是即时绘制还是队列处理 */
            if (this.queue) {
                this.stage().addQueueTask(() => {
                    that._action.draw(); //绘制选中状态
                })
            } else {
                that._action.draw(); //绘制选中状态
            }
        }


    }


    /**
     * 获取图形边界范围的坐标
     * @param corner
     * @returns {{x: (number|string|*), y: (number|string|*)}|{x: *, y: (number|string|*)}|{x: (number|string|*), y: *}|{x: *, y: *}|[{x: (number|string|*), y: (number|string|*)},{x: *, y: (number|string|*)},{x: (number|string|*), y: *},{x: *, y: *}]}
     */
    coords(corner = null) {

        const coords = [
            {x: this.x, y: this.y},
            {x: this.x + this.w, y: this.y},
            {x: this.x, y: this.y + this.h},
            {x: this.x + this.w, y: this.y + this.h},
            {x: this.x + (this.w / 2), y: this.y},
            {x: this.x + this.w, y: this.y + (this.h / 2)},
            {x: this.x, y: this.y + (this.h / 2)},
            {x: this.x + (this.w / 2), y: this.y + (this.h / 2)}
        ]

        if (corner === null) {
            return coords;
        } else {
            return coords[corner];
        }

    }


    /*
    * 获取边界范围
    * */
    bounds() {
        return {
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h
        }
    }


    /**
     * 相对位置
     */
    relative() {

        /* 舞台的边界范围 */
        const bounds = this.getReference();


        return {
            x: (((this.x - bounds._x) / bounds._w) * 100).toFixed(2) + "%",
            y: (((this.y - bounds._y) / bounds._h) * 100).toFixed(2) + "%",
            w: ((this.w / bounds._w) * 100).toFixed(2) + "%",
            h: null,
        }

    }


    /**
     * 初始化图形边界范围
     * @return {*}
     */
    initColorBounds() {
        /* 计算边界 */
        this._colorBounds = this.ColorBounds();
        return this._colorBounds;
    }

}