/*
* @author 友人a丶
* @date 2022-07-11
*
* 基本的网络请求接口封装
*
* */


import axios from "axios";
// import api from './api';
import load from "@/common/load";
import config from "@/config";
import {router} from "@/router";
import {getParamsObject} from "@/common/common";
// import qs from 'qs'

let BASE_URL = import.meta.env.PROD ? "" : "";
let tipsText = '';
/*
* 是否需要注册认证头
* */
const xsrfHeaderName = config.auth.token; //认证头的名字
axios.defaults.timeout = 120000;
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = xsrfHeaderName;
axios.defaults.xsrfCookieName = xsrfHeaderName;


const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 120000, // 请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
    (response) => {
      // 在响应数据之前做些什么，例如处理数据格式等操作
      // eslint-disable-next-line no-debugger
      let code = response.data.code
      let msg = response.data.msg
      
      if (response.status == 200 && (code == 200 || code == 1)) {
        // 处理请求成功的逻辑
        return response.data;
      } else if(code === 403) {
        router.push({ 
          path: `/login`,
          query: { 
            redirect: encodeURIComponent(JSON.stringify(getParamsObject())),
          }
        });
      } else {
        if(tipsText) {
          load.error(tipsText);
          tipsText = '';
        }
        // 对非200状态码进行统一报错处理
        const error = new Error(msg);
        error.response = response;
        throw error;
      }
    },
    (error) => {
      // 对响应错误做些什么
      let { message } = error;
      if (message == "Network Error") {
        message = "Network Error";
      } else if (message.includes("timeout")) {
        message = "timeout";
      } else if (message.includes("Request failed with status code")) {
        message = "Request failed with status code";
      }
      load.error(message);
      return Promise.reject(error);
    }
);

// 封装通用的 HTTP 请求方法
export const httpRequest = {
    post(url, data, text) {
      tipsText = text;
        return instance.post(url, JSON.stringify(data), {
            headers: {
              'Content-Type': 'application/json',
            },
        });
    },
    get(url, params) {
      // 将参数对象转换为 URL 查询字符串
    //   const parameter = qs.stringify(params);
    //   const url = `${api}?${parameter}`;
      return instance.get(url, { params });
    },
    put(url, data) {
      return instance.put(url, data);
    },
    delete(url) {
      return instance.delete(url);
    },
    // 上传文件的方法
    upload(url, file, text) {
      tipsText = text;
      const formData = new FormData();
      formData.append('file', file);
      return instance.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
};