// zh.js
export default {
    save: "保存",
    changeStyle: "切换款式",
    cacheTips: "您有一个未完成的作品,是否继续？",
    ContinuingCreate: "继续创作",
    discard: "放弃",
    stickers: "素材",
    font: "文字",
    image: "照片",
    loading: "加载中...",
    templates: "热款模板",
    clearCavans: "清空",
    drafts: "草稿箱",
    amplify: "放大",
    reduce: "缩小",
    layers: "图层",
    back: "背面",
    fore: "正面",
    saveMydrafts: "已保存至我的作品",
    selectedMaterial: "请重新选择材质",
    make: "制作中",
    noPhoneCase: "暂无可以售卖的手机壳，请选择其它型号",
    t_shirt: "短袖T恤",
    long_t_shirt: "长袖T恤",
    crewneckHoodie: "圆领卫衣",
    hoodie: "连帽卫衣",
    noClothes: "该类目下没有衣服出售",
    noDrafts: "暂无草稿记录",
    myDrafts: "我的草稿",
    noMore: "没有更多了",
    typeHere: "点击输入文案",
    style: "样式",
    fonts: "字体",
    patternedText: "花字",
    clothingColor: "衣服颜色",
    patternSize: "图案尺寸",
    largeArea: "大区域",
    nextStep: "确定",
    loadError: "加载错误",
    customized: "去定制",
    selectModel: "选择型号",
    noData: "暂无数据",
    deleteSuccess: "删除成功",
    switch: "切换",
    model: "型号",
    brand: "品牌",
    publish: "发布作品",
    Continuing: "继续设计",
    delete: "删除",
    cutout: "一键抠图",
    hd: "高清",
    adjust: "调整层级",
    fineTuning: "微调",
    overturn: "翻转",
    copy: "复制",
    layerAdjuster: "调整图层",
    topLayer: "置于顶层",
    bottomLayer: "置于底层",
    bringForward: "上移一层",
    sendBackward: "下移一层",
    moveUp: "上移",
    down: "下移",
    leftShift: "左移",
    rightShift: "右移",
    flipHorizontal: "水平翻转",
    flipVertical: "垂直翻转",
    imageMatting: "人像抠图",
    petCutout: "宠物抠图",
    articleCutout: "物品抠图",
    cutout1: "抠图",
    replacePhoto: "替换照片",
    replace: "替换",
    loginFailure: "登录失效，请返回重新登录",
    confirm: "确认",
    saveDrafts: "保存草稿"
}
