/*
* @author 友人a丶
* @date
*
* */

import Action from "./action";

export default class Clear extends Action {

    /**
     * 删除
     */
    tap() {
        /* 删除图形 */
        this.shape.destroy(true);
    }

}