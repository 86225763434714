/*
* @author 友人a丶
* @date 2022-07-11
*
* 系统设置
* */

export default {
    /*权限验证模式*/
    auth: {
        mode: 'token', //token、cookie
        token: 'token' //token是需要填写认证头
    }
}