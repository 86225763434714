/*
* @author 友人a丶
* @date
*
* */

import Action from "./action";

export default class Do extends Action {

    /**
     * 绘制图形路径
     * @private
     */
    _draw() {

        /** @type {CanvasRenderingContext2D} */
        const context = this.shape.getContext(); //上下文
        context.beginPath();
        this.shape._rotate(); //旋转
        /* 绘制图片的路径信息，用户判断是否被点击 */
        context.rect(this.x, this.y, this.w, this.h);
        context.closePath();
    }


    /**
     *  图形绘制
     */
    draw() {


        /* 离屏还是绘制 */
        /** @type {CanvasRenderingContext2D} */
        const context = this.shape.getContext(); //上下文

        let coords = this.shape.coords(this.corner);

        let {
            x,
            y
        } = this._coords(coords); //获取位置、长宽


        /* 保存绘制状态 */
        context.save(true, false);

        /* 重新开始新的路径 */
        context.beginPath();

        /* 旋转的效果 */
        this.shape._rotate();

        /* 混合模式 */
        context.globalCompositeOperation = this.blendMode;

        /* 绘制，并更新边界范围 */
        Object.assign(this, context.drawText({
            x: x,
            y: y,
            border: 1,
            borderColor: "#e3e3e3",
            textColor: '#000000',
            fontSize: 10,
            cornerRadius: 8,
            padding: {
                top: 5,
                bottom: 5,
                left: 8,
                right: 8
            },
            callback: false,
            text: this.shape.button_2_Text
        }));


        /* 关闭路径 */
        context.closePath();
        /* 恢复 */
        context.restore();
    }


    /**
     * 删除
     */
    tap() {
        /* 触发替换事件 */
        this.shape.stage().emit('to-do', {type: 'to-do', shape: this.shape});
    }

}