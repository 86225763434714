/**
 * @date 2023/10/17
 * @author 爱心发电丶
 */
import Action from '../../shape/action'

export default {

    _stage: null, //舞台对象
    _context: null, //上下文，上下文是可以切换的，借助离屏绘制时，是离屏canvas的上下文对象
    _preview: null, //可预览链接


    /* 运行时 */
    _startAgree: null, //记录初始旋转的角度
    _startBounds: null, //记录初始大小
    _action: null, //绑定的变形组件
    _acting: false, //是否触发变形组件

    /* action组件进行操作识别的 */
    _previous: null,
    _group: null, //所在编组

    name: null, //图形名称
    type: null, //图形的类
    zIndex: 0, //所处层级
    fixed: null, //是否需要固定图层
    actived: false, //是否被激活
    clicked: false, //是否被点击
    queue: true, //使用队列绘制active状态
    _colorBounds: null, //非透明区域的边界


    blendMode: 'source-over', //混合模式
    rotateRadius: 0, //旋转角度
    rotateAroundCenter: true, //中心点旋转
    button_2_Text: "", //按钮文字
    reference: "diy", //百分比参考的边界范围 diy|stage

    /* canvas绘图属性 */
    stroke: true, //是否需要进行绘制
    fill: true, //是否需要进行填充
    strokeStyle: "black", //绘制的颜色
    fillStyle: "transparent", //填充的颜色
    fillRule: "nonzero",
    outline: false, //是否绘制边界


    /*** 选中和激活 ****
     *  选中状态会绘制Transform变形组件
     *  激活状态会响应当前画布的move、rotate、scale等操作
     */
    canMove: true, //能否响应移动
    canRotate: true, //能否响应旋转
    canScale: true, //能否响应缩放
    canUp: true,//能否响应鼠标抬起
    canRemove: true, //是否可以移除这个对象
    canClick: true, //是否可以被选中
    canActive: true, //是否可以被激活
    canExport: true, //导出画布时是否绘制
    canSort: true, //是否允许改变图层
    canDo: false, //是否允许响应额外动作
    canFlip: false, //是否允许翻转图片

    /**
     * 添加到画布后的初始化方法
     * */
    async init() {

        /* 如果定义了初始化方法 */
        if (this._init) {
            await this._init(); //初始化图形
        }

        /* 是否需要添加action组件 */
        if (this.canActive && !this._action) {
            /* 初始化组件 */
            this._action = new Action({
                shape: this
            });
        }

        /* 触发初始化事件 */
        this.emit("init", {type: 'init', stage: this._stage});
        this.render(); //渲染
    },


    /**
     * 覆盖配置
     * @param config
     */
    set(config) {
        /* 绑定 */
        this.each(config);
        /* 重新渲染 */
        this.render();
    },


    /**
     * 属性绑定
     * @param config
     */
    each(config) {
        /* 覆盖配置 */
        for (let i in config) {
            this[i] = config[i];
        }
    },


    /**
     * 点击事件检测
     * @param x
     * @param y
     * @param flag
     * @param coords 单词触摸点，才会触发action
     * @returns {boolean|*|boolean}
     */
    click(x, y, flag = false, coords = []) {

        /* 如果组件被激活 */
        if (this.canActive) {
            if (this.actived && coords.length < 2) {
                /* 判断变形组件是否被点击 */
                if (this._action.click(x, y)) {
                    this._acting = true;
                    return true;
                } else {
                    this._acting = false;
                }
            }
        }


        /* 如果不可被激活 */
        if (!this.canClick) {
            this.clicked = false;
            return false;
        }


        /* 需要直接标记 */
        if (flag) {
            this.clicked = true;
            return true;
        }

        /** @type {CanvasRenderingContext2D} */
        const context = this._context;
        context.save(); //保存状态

        this._draw(); //绘制选中路径


        /* 通过路径判断点击状态 */
        this.clicked = context.isPointInPath(x, y);
        context.restore(); //恢复状态

        /* 是否需要触发点击事件 */
        if (this.clicked) {
            /* 触发点击事件 */
            this.emit("click", {type: 'click', x, y});
        }

        return this.clicked;

    }


}