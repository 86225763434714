import Action from './action'
import Event from './event'
import Meta from './meta'
import Render from './render'
import State from './state'
import Snap from './snap'

class Shape {
    /**
     * @param config 图形的配置
     */
    constructor(config) {
        /* 当前对象的默认配置 */
        /* _为私有属性，导出时不会被处理 */
        Object.assign(this, config); //配置合并

        /* 事件对象 */
        this._listener = new Map();

        /* 运行时参数 */
        this._previous = {
            x: null,
            y: null,
            zoom: null,
            rotate: null
        };
    }
}

/* 原型组合 */
Object.assign(Shape.prototype, Meta, Action, Event, State, Render, Snap);
/* 导出 */
export default Shape

