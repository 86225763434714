// 手机模型
const phoneModel = (foreImg, foreDiyImg) => {
  return [
    {
      back: [
        {
          x: "18.00%",
          y: "16.00%",
          w: "60.00%",
          h: null,
          url: foreImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2024-04-02/537a022283e44dafb8b9d658802d1480.png",
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "18.00%",
        y: "16.00%",
        w: "60.00%",
        h: null,
        url: foreDiyImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2024-04-02/6b44c3c07e8f44eea9a34fb2d57c7ce2.png",
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
  ];
};

// 短袖模型
const shortTModal = (foreImg, foreDiyImg, backImg, backDiyImg) => {
  return [
    {
      back: [
        {
          x: "0.00%",
          y: "0.00%",
          w: "100.00%",
          h: null,
          url: 'https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2024-03-23/1e8d0522f0164076bde80693d42891f6.png' || foreImg,
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "0.00%",
        y: "0.00%",
        w: "100.00%",
        h: null,
        url: 'https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-07/2deb1e61734a4b7eb43f542ba6f93d74.png' || foreDiyImg,
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
    {
      back: [
        {
          x: "0.00%",
          y: "0.00%",
          w: "100.00%",
          h: null,
          url: 'https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2024-03-23/4d63bf8881b049e7bb5f1cd69598ea04.png' || backImg,
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "0.00%",
        y: "0.00%",
        w: "100.00%",
        h: null,
        url: 'https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-07/36ece577ccbb47fe8ba67965c93eb417.png' || backDiyImg,
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
  ];
};

// 长袖模型
const LongTModal = (foreImg, foreDiyImg, backImg, backDiyImg) => {
  return [
    {
      back: [
        {
          x: "0.00%",
          y: "0.00%",
          w: "100.00%",
          h: null,
          url: foreImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-08/a874f1621f7544e4a596d4e971a73716.png",
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "0.00%",
        y: "0.00%",
        w: "100.00%",
        h: null,
        url: foreDiyImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-08/f7c035c6c2d947f1896d3bd40a8f8b95.png",
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
    {
      back: [
        {
          x: "0.00%",
          y: "0.00%",
          w: "100.00%",
          h: null,
          url: backImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-08/7a3df45deb8044aab8a5939bb7199345.png",
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "0.00%",
        y: "0.00%",
        w: "100.00%",
        h: null,
        url: backDiyImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-08/f7c035c6c2d947f1896d3bd40a8f8b95.png",
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
  ];
};

// 圆领卫衣模型
const hoodiesModal = (foreImg, foreDiyImg, backImg, backDiyImg) => {
  return [
    {
      back: [
        {
          x: "0.00%",
          y: "0.00%",
          w: "100.00%",
          h: null,
          url: foreImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-12/0d4edefd794e4da1b928b6afcbc4c0cf.png",
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "0.00%",
        y: "0.00%",
        w: "100.00%",
        h: null,
        url: foreDiyImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-12/dcb5e445525f48f480d8a18ede0e8b76.png",
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
    {
      back: [
        {
          x: "0.00%",
          y: "0.00%",
          w: "100.00%",
          h: null,
          url: backImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-12/42bd98066c024d9baf6f720a35733f9b.png",
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "0.00%",
        y: "0.00%",
        w: "100.00%",
        h: null,
        url: backDiyImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-12/dcb5e445525f48f480d8a18ede0e8b76.png",
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
  ];
};

// 圆领卫衣模型
const hoodedHoodie = (foreImg, foreDiyImg, backImg, backDiyImg) => {
  return [
    {
      back: [
        {
          x: "0.00%",
          y: "0.00%",
          w: "100.00%",
          h: null,
          url: foreImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-13/f9a5e268da374a038bd0dc13eceae188.png",
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "0.00%",
        y: "0.00%",
        w: "100.00%",
        h: null,
        url: foreDiyImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-13/f9ea6b2772fb4d988a7e6d3140c9a716.png",
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
    {
      back: [
        {
          x: "0.00%",
          y: "0.00%",
          w: "100.00%",
          h: null,
          url: backImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-13/84840c3011d745b9a6a1c07f8399c221.png",
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "0.00%",
        y: "0.00%",
        w: "100.00%",
        h: null,
        url: backDiyImg || "https://shopfiy-us.oss-accelerate.aliyuncs.com/dw-diy/2023-12-13/85277e77ffc74cc7bc66b165f7320d2e.png",
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
  ];
};
// 抱枕
const pillowModal = (foreImg, foreDiyImg) => {
  return [
    {
      back: [
        {
          x: "10%",
          y: "20.00%",
          w: "80.00%",
          h: null,
          url: foreImg || "https://douyin.nicen.cn/icon/bad1.png",
          originWidth: 830,
          originHeight: 1217,
          type: "Image",
          button_2_Text: "replace",
          canReplace: true,
          canFlip: true,
          flip: {
            x: 1,
            y: 1,
          },
          name: " 背景",
          reference: "stage",
          fixed: 1,
          canClick: false,
          canActive: false,
          zIndex: -1,
          actived: false,
          clicked: false,
          queue: true,
          blendMode: "source-over",
          rotateRadius: 0,
          rotateAroundCenter: true,
          stroke: true,
          fill: true,
          strokeStyle: "black",
          fillStyle: "transparent",
          fillRule: "nonzero",
          outline: false,
          canMove: true,
          canRotate: true,
          canScale: true,
          canUp: true,
          canRemove: true,
          canExport: true,
          canSort: true,
        },
      ],
      fore: [],
      diy: {
        x: "10%",
        y: "20.00%",
        w: "80.00%",
        h: null,
        url: foreDiyImg || "https://douyin.nicen.cn/icon/bad2.png",
        originWidth: 830,
        originHeight: 1217,
        type: "Image",
        button_2_Text: "replace",
        canReplace: true,
        canFlip: true,
        flip: {
          x: 1,
          y: 1,
        },
        name: " 范围",
        blendMode: "destination-in",
        reference: "stage",
        fixed: -1,
        outline: false,
        canClick: false,
        canActive: false,
        canSort: false,
        strokeStyle: "#eb2f96",
        zIndex: -1,
        actived: false,
        clicked: false,
        queue: true,
        rotateRadius: 0,
        rotateAroundCenter: true,
        stroke: true,
        fill: true,
        fillStyle: "transparent",
        fillRule: "nonzero",
        canMove: true,
        canRotate: true,
        canScale: true,
        canUp: true,
        canRemove: true,
        canExport: true,
      },
    },
  ];
};

export const qrModalData = (type, images = {}) => {
  // eslint-disable-next-line no-debugger
  const {foreImg, foreDiyImg, backImg, backDiyImg} = images
  if (type == 1232) {
    // 短袖
    return shortTModal(foreImg, foreDiyImg, backImg, backDiyImg);
  } else if (type == 1231) {
    // 手机壳
    return phoneModel(foreImg, foreDiyImg);
  } else if (type == 1233) {
    // 长袖
    return LongTModal(foreImg, foreDiyImg, backImg, backDiyImg);
  } else if (type == 1234) {
    // 圆领卫衣
    return hoodiesModal(foreImg, foreDiyImg, backImg, backDiyImg);
  } else if (type == 1265) {
    // 连帽卫衣
    return hoodedHoodie(foreImg, foreDiyImg, backImg, backDiyImg);
  } else if (type == 1268) {
    // 抱枕
    return pillowModal(foreImg, foreDiyImg);
  }
};
