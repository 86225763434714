/**
 * @author 爱心发电丶
 * @date 2023-10-17
 * 一些数学函数
 */


/**
 * 保留指定位数的小数，并且不进行四舍五入
 * @param num
 * @param decimalPlaces
 * @returns {number}
 */
export function toFixed(num, decimalPlaces) {
    // 计算10的指数
    let factor = Math.pow(10, decimalPlaces);
    // 乘以10的指数，取整，再除以10的指数
    return Math.floor(num * factor) / factor;
}


/**
 * 百分比转数值
 * @param percentage
 * @param total
 * @returns {number|string}
 */
export function percentToValue(percentage, total) {
    if (typeof percentage === 'string' && percentage.endsWith('%')) {
        const numericValue = parseFloat(percentage);
        return (numericValue / 100) * total;
    }

    return percentage;
}


/**
 * 指定小数位保留
 * @param number
 * @param decimalPlaces
 */
export function toFixedNumber(number, decimalPlaces) {
    return parseFloat(number.toFixed(decimalPlaces));
}