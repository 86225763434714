/*
* @author 友人a丶
* @date 2022-07-11
*
* 全局接口
* */


const BASE_URL = import.meta.env.PROD ? "https://ding.nicen.cn" : "https://ding.nicen.cn";
const BASE_CDN = import.meta.env.PROD ? "https://ding.nicen.cn" : "https://ding.nicen.cn";

export default {
    host: `${BASE_URL}`,//全局host
    cdn: BASE_CDN,
    /* 用户登录 */
    login: "/login",
    /* 加载指定模型 */
    model: "/model",
    /* 加载素材分类 */
    cats: "/cats",
    /* 加载素材 */
    assets: "/assets1",
    /* 字体列表 */
    fonts: '/fonts',
    /* 文字组合 */
    groups: '/groups',
    /* 模板组合 */
    demos: '/demos',
    /* 指定字体名称获取资源链接 */
    font: '/family'
}