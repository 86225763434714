/*
* @author 友人a丶
* @date 2022-07-11
* 自动调整rem的大小
* */


export default function () {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

    let l = () => {

        let r = document.documentElement, o = r.offsetWidth / 23.5;
        o < 12 && (o = 12), r.style.fontSize = (isMobile ? o : 16) + "px", window.rem = o
    };

    l(); //初始化

    window.addEventListener("resize",()=>l());

}
