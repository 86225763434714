<template>
  <img src="./assets/images/loading.png" alt="" class="img-loading">
  <router-view/>
</template>

<script setup>
import {useWindowSize} from "@vueuse/core";
import { onMounted } from "vue";

onMounted(()=> {
  // 判断是否为移动端
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  
  /* 窗口大小 */
  // if(!isMobile) {
    // }
  if(/iPad/i.test(navigator.userAgent)) {
    window.document.documentElement.style.maxWidth = `90%`
    window.document.body.style.maxWidth = `100%`
  }else if(isMobile){
    const {width, height} = useWindowSize();
    window.document.body.style.height = `${height.value}px`
    window.document.body.style.width = `${width.value}px`
  }else {
    const {height} = useWindowSize();
    window.document.body.style.height = `${height.value}px`
  }
})

</script>

<style lang="scss">

html {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f9f9f9;
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
  transform: rotate(0);

  /* 禁止IOS页面缩放 */
  touch-action: pan-x pan-y;
  user-scalable: no;


  /* 样式新增 */
  -webkit-touch-callout: none; /* 禁止长按链接弹出菜单(iOS Safari) */
  -webkit-user-select: none; /* 禁止用户选择 */
  -khtml-user-select: none; /* 其他旧版KHTML浏览器 */
  -moz-user-select: none; /* 禁止用户选择 */
  -ms-user-select: none; /* 禁止用户选择 */
  user-select: none; /* 禁止用户选择 */
  -webkit-touch-callout: none; /* 禁止长按链接弹出菜单(iOS Safari) */
  -webkit-user-select: none; /* 禁止用户选择 */
  -khtml-user-select: none; /* 其他旧版KHTML浏览器 */
  -moz-user-select: none; /* 禁止用户选择 */
  -ms-user-select: none; /* 禁止用户选择 */
  user-select: none; /* 禁止用户选择 */
  
  @font-face {
    font-family: 'Assistant';
    src: url('./assets/fonts/Assistant-VariableFont_wght.ttf') format('woff');
  }
  #app {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-family: 'Assistant', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
  }
}
html, body {
  max-width: 540px;
  margin: 0 auto;
}
// @media screen and (min-width: 540px) {
//   html, body {
//     width: 540px;
//     margin: 0 auto;
//   }
// }
.van-overlay {
  background-color: rgba(0, 0, 0, 0.3) !important; /* 设置遮罩层颜色和透明度 */
}
.img-loading {
  width: 50px;
  position: fixed;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}
.van-dialog--round-button .van-action-bar-button--last {
  background: #121212;
}
</style>
