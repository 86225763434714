/* eslint-disable */

/*
* @author 友人a丶
* @date 2022-07-11
*
* 加载全局路由守卫
* */


import {router} from "@/router";
import systems from "@/stores/system";
import loadUser from "@/service/load-user";

const whiteList = ['/login', '/register']

// 不需要拦截的路由配置
const ignoreRoute = {
    names: ['404', '403'],      //根据路由名称匹配
    paths: ['/login'],   //根据路由fullPath匹配
    /**
     * 判断路由是否包含在该配置中
     * @param route vue-router 的 route 对象
     * @returns {boolean}
     */
    includes(route) {
        return ignoreRoute.names.includes(route.name) || ignoreRoute.paths.includes(route.path)
    }
}

/*
* 加载路由守卫
* */
export default function () {

    console.log("加载路由守卫...");

    /*
    * 判断系统是否初始化
    * 加载用户信息
    * */
    router.beforeEach(async (to, from, next) => {
        let userToken = sessionStorage.getItem("userToken");

        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        }else if(!userToken) {
            next({ 
                path: '/login',
                query: { 
                    redirect: encodeURIComponent(JSON.stringify(to.query)),
                }
            });
        } else {
            next(); //下一个
        }
    });


    /*
    * 切换页面标题
    * */
    router.beforeEach((to, from, next) => {
        document.title = to.meta.name
        next();
    })


}

