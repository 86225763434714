/**
 * @date 2023/12/7
 * @author 爱心发电丶
 * 快照相关功能
 */
export default {


    /**
     * 保存当前图形的配置的快照
     */
    save() {
        return this.props();
    },

    /**
     * 恢复保存的图形配置
     */
    restore(snap) {
        this.each(snap);
    },

}