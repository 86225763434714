/*
* @author 友人a丶
* @date 
* 组件公共方法
* */

import load from "@/common/load";
import global from "./global";
import {loadImage} from "@/diy/utils/common";
import { qrModalData } from "@/common/model"
import {getFontUrl} from "@/api/index"

/**
 * 初始化模型
 */
export async function initModel(json, config = {}) {

    const stage = global.stage;

    /* 不包括正面结构 */
    if (!Array.isArray(json)) {
        load.error("Data abnormality!");
        return;
    }


    /* 舞台初始化完毕 */
    if (stage) {

        /* 标记有反面 */
        if (json.length > 1) global.hasBack = true;

        /* 正面激活，或者只有一个面时加载正面数据 */
        if (global.active === 'fore' || json.length === 1) {

            await stage.loadJson(json[0], config);
            global.fore.json = json[0]; //保存json
            global.fore.snap = stage.snap(true); //保存快照
            global.active = 'fore'; //标记为正面激活

        } else {

            /* 保存到快照 */
            global.fore.snap = await stage.loadJsonWithSnap(json[0], config);
            global.fore.json = json[0]; //保存json

        }

        /* 判断是否需要加载反面数据 */
        if (json.length > 1 && json[1]) {

            /* 判断激活的是不是反面 */
            if (global.active === 'back') {

                await stage.loadJson(json[1], config);
                global.back.json = json[1]; //保存json
                global.back.snap = stage.snap(true); //保存快照
                global.active = 'back'; //标记为正面激活

            } else {

                global.back.snap = await stage.loadJsonWithSnap(json[1], config);
                global.back.json = json[1]; //保存json

            }

        } else {

            /* 清理 */
            global.back.json = null;
            global.back.snap = null;
            global.hasBack = false;
        }


        /* 每次切换模型，删除所有快照 */
        stage.clearSnap();

    }
}

/**
 * 加载模型
 * @param id
 * @param config
 */
export async function loadModel(id, config = {}, i18n) {

    /* 显示加载效果 */
    load.loading(i18n('loading'));

    try {
        const json = qrModalData(id);
        await initModel(json, config); //初始化模型
        load.loaded(100); //关闭加载效果
    } catch (e) {
        console.log(e)
        load.error(e);
    }


}


/**
 * 更换背景图
 */
export async function setBack(url) {


    /* 类型检测 */
    if (!Array.isArray(url)) {
        console.warn("参数异常");
        return;
    }

    /* 有效性检测 */
    if (url.length === 0) {
        return;
    }

    let shapeFore, shapeBack;
    const all = []; //Promise

    /* 判断是哪一面 */
    if (global.active === 'fore') {

        shapeFore = global.stage.getBackgroundShapes(true, 0);

        all.push(new Promise(resolve => {
            global.fore.json.back[0].url = url[0];
            loadImage(url[0], (image) => {
                /* 更新图像 */
                shapeFore.update({image});
                resolve();
            })
        }));

        /* 如果存在背面 */
        if (global.back.snap && url.length > 1) {

            /* 获取第一个背景图形 */
            shapeBack = global.back.snap.back[0];

            /* 图形存在 */
            if (shapeBack) {
                all.push(new Promise(resolve => {
                    global.back.json.back[0].url = url[1];
                    loadImage(url[1], (image) => {
                        /* 更新图像 */
                        shapeBack.update({image});
                        resolve();
                    })
                }));
            }


        }

    } else {

        shapeBack = global.stage.getBackgroundShapes(true, 0);

        all.push(new Promise(resolve => {
            global.back.json.back[0].url = url[1];
            loadImage(url[1], (image) => {
                /* 更新图像 */
                shapeBack.update({image});
                resolve();
            })
        }));

        /* 如果存在背面 */
        if (global.fore.snap && url.length > 1) {

            /* 获取第一个背景图形 */
            shapeFore = global.fore.snap.back[0];

            /* 图形存在 */
            if (shapeFore) {
                all.push(new Promise(resolve => {
                    global.fore.json.back[0].url = url[0];
                    loadImage(url[0], (image) => {
                        /* 更新图像 */
                        shapeFore.update({image});
                        resolve();
                    })
                }));
            }


        }
    }


    /* 等待处理 */
    await Promise.all(all);
    global.stage.renderBackground(); //刷新背景
}

/**
 * 加载字体
 */
export function loadFont(family, url) {
    return new Promise(resolve => {

        const font = new FontFace(family, `url(${url})`);

        font.load().then(() => {
            document.fonts.add(font); //添加到主体
            resolve(true);
        }).catch((error) => {
            console.log(error)
            resolve(false);
        });
    });
}


/**
 * 加载指定名称的字体
 */
// eslint-disable-next-line no-unused-vars
export function loadFontFamily(fontFamily, callback) {

    try {

        console.log(fontFamily)
        getFontUrl(fontFamily.split('-')[1]).then(async res=> {
            if(res.code === 200) {
                /* 加载字体文件 */
                callback(await loadFont(fontFamily, res.msg));
            }
        })
    } catch (e) {
        console.log(e)
        load.toast(e);
    }

}