/* eslint-disable */

/*
* @author 友人a丶
* @date 
* 
* */

export default class Action {


    /* 构造函数 */
    constructor(config) {
        /* 初始化配置 */
        Object.assign(this, {
            image: null,
            corner: null,
            blendMode: 'source-over', //混合模式
            gap: 3,
            w: 24,
            h: 24,
            shape: null,
            strokeStyle: "#e3e3e3",
            canActive: false,
            canRemove: false,
            canRotate: false,
            canScale: false,
            canExport: false,
            canMove: false
        }, config);
    }


    /**
     * 计算位置
     * @private
     */
    _coords(coords) {

        if (this.corner === 0) {
            return {
                x: coords.x - this.gap,
                y: coords.y - this.gap,
            }
        } else if (this.corner === 1) {
            return {
                x: coords.x + this.gap,
                y: coords.y - this.gap,
            }
        } else if (this.corner === 2) {
            return {
                x: coords.x - this.gap,
                y: coords.y + this.gap,
            }
        } else if (this.corner === 3) {
            return {
                x: coords.x + this.gap,
                y: coords.y + this.gap,
            }
        } else if (this.corner === 4) {
            return {
                x: coords.x,
                y: coords.y - this.h - this.gap,
            }
        } else if (this.corner === 5) {
            return {
                x: coords.x + this.gap + this.w,
                y: coords.y,
            }
        } else if (this.corner === 6) {
            return {
                x: coords.x - this.w - this.gap,
                y: coords.y,
            }
        } else if (this.corner === 7) {
            return {
                x: coords.x + this.gap,
                y: coords.y + this.gap + this.h,
            }
        }
    }

    /**
     * 绘制图形路径
     * @private
     */
    _draw() {

        /* 图片对象是否存在 */
        if (!this.image) return;

        /** @type {CanvasRenderingContext2D} */
        const context = this.shape.getContext(); //上下文

        let coords = this.shape.coords(this.corner);

        let {
            x,
            y
        } = this._coords(coords); //获取位置、长宽

        context.beginPath();

        this.shape._rotate(); //旋转

        /* 绘制图片的路径信息，用户判断是否被点击 */
        context.rect(x - ((this.w + 10) / 2), y - ((this.h + 10) / 2), (this.w + 10), (this.h + 10));
        context.closePath();


    }


    /**
     *  图形绘制
     */
    draw() {


        /* 离屏还是绘制 */
        /** @type {CanvasRenderingContext2D} */
        const context = this.shape.getContext(); //上下文

        let coords = this.shape.coords(this.corner);

        let {
            x,
            y
        } = this._coords(coords); //获取位置、长宽

        /* 重新开始新的路径 */
        context.beginPath();

        /* 保存绘制状态 */
        context.save(true,false);

        /* 旋转的效果 */
        this.shape._rotate();

        /* 混合模式 */
        context.globalCompositeOperation = this.blendMode;

        const r = this.w / 2;//半径

        /* 绘制 */
        context.drawImage(this.image, x - r, y - r, this.w, this.h);
        /* 绘制 */
        context.arc(x, y, r - 1, 0, 2 * Math.PI);
        /* 设置了绘制的颜色 */
        context.strokeStyle = this.strokeStyle;
        /* 绘制的线宽 */
        context.lineWidth = 1;
        /* 绘制 */
        context.stroke();

        /* 关闭路径 */
        context.closePath();
        /* 恢复 */
        context.restore();
    }


    /**
     * 点击事件检测
     * @param x
     * @param y
     * @returns {boolean|*|boolean}
     */
    click(x, y) {

        /** @type {CanvasRenderingContext2D} */
        const context = this.shape.getContext(); //上下文

        /* 保存绘制状态 */
        /* click检测事件里，没有上传scale */
        context.save();

        this._draw(); //绘制选中路径

        /* 通过路径判断点击状态 */
        this.clicked = context.isPointInPath(x, y);

        context.restore();

        return this.clicked;

    }


    /**
     *  图形移动
     * @param coords 位置数组
     * @param offset 触摸起始点和原点的偏差值
     * @returns {boolean}
     */
    move(coords, offset) {

    }


    /**
     * 点击事件
     * @param coords 触摸点
     */
    tap(coords) {

    }


    /**
     * 释放事件
     * @param coords 触摸点
     */
    up(coords) {

    }


}