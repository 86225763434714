/*
* @author 友人a丶
* @date
*
* */

import Action from "./action";
import {getLength, getVector} from "../../utils/vector";

export default class Scale extends Action {

    startScale; //初始大小
    rectCenterPoint; //中心点

    /**
     *  图形移动
     * @param coords 位置数组
     * @returns {boolean}
     */
    move(coords) {


        /* 初始大小 */
        if (!this.startScale) {

            let {
                x,
                y,
                w,
                h
            } = this.shape.bounds(); //获取位置、长宽

            this.rectCenterPoint = {
                x: x + w / 2,
                y: y + h / 2
            }; // 矩形中心点


            this.startScale = getLength(getVector(this.rectCenterPoint, coords[0]));
        }


        let current = getLength(getVector(this.rectCenterPoint, coords[0]));
        this.shape.scale(current / this.startScale, coords); //缩放
    }


    /**
     * 释放事件
     */
    up() {
        this.startScale = null;
        this.rectCenterPoint = null;
    }


}