/**
 * @date 2023/10/18
 * @author 爱心发电丶
 */
import Image from "./image";
import Text from "./text"
import Group from "./group"


export default {
    Image,
    Text,
    Group
}