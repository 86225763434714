/* eslint-disable */
import iScale from './icon/scale.png';
import iRotate from './icon/rotate.png';
import iClear from './icon/clear.png';

import Clear from './clear'
import Rotate from './rotate'
import Scale from './scale'
import Do from "../../shape/action/do";
import {loadImage} from "../../utils/common";


/**
 * @date 2023/10/17
 * @author 爱心发电丶
 */
export default class Action {

    /* 构造函数 */
    constructor(config = {}) {
        /* 数据初始化 */
        Object.assign(this, {
            /* 被触发的action */
            active: null,
            /** @type Shape 关联的图形 */
            shape: null,
            /* 操作组件 */
            action: [],
            /* 间隙 */
            gap: 3
        }, config);


        /* 能删除 */
        if (this.shape.canRemove) {
            loadImage(iClear, (clear) => {
                /* 添加 */
                this.action.push(new Clear({
                    image: clear,
                    corner: 0,
                    shape: this.shape
                }));
                /* 刷新 */
                this.shape.render();
            });
        }


        /* 能旋转 */
        if (this.shape.canRotate) {
            loadImage(iRotate, (rotate) => {
                /* 添加 */
                this.action.push(new Rotate({
                    image: rotate,
                    corner: 5,
                    text: 'replace',
                    shape: this.shape
                }));
                /* 刷新 */
                this.shape.render();
            });
        }


        /* 能放大 */
        if (this.shape.canScale) {
            loadImage(iScale, (scale) => {
                /* 添加 */
                this.action.push(new Scale({
                    image: scale,
                    corner: 3,
                    shape: this.shape
                }));
                /* 刷新 */
                this.shape.render();
            });
        }


        /* 能响应动作 */
        if (this.shape.canDo) {
            /* 添加 */
            this.action.push(new Do({
                corner: 1,
                shape: this.shape
            }));
            /* 刷新 */
            this.shape.render();
        }
    }


    /**
     * 渲染
     * @returns {boolean}
     */
    draw() {

        /* 没有绑定图形 */
        if (!this.shape) return false;

        /* 边界烦我 */
        let {
            x,
            y,
            w,
            h
        } = this.shape.bounds();


        /** @type CanvasRenderingContext2D */
        const context = this.shape.getContext();
        /* 获取配置 */
        const config = this.shape.stage().getConfig();


        /* 重新开始新的路径 */
        context.beginPath();
        /* 保存绘制状态 */
        context.save(true, false);

        /* 旋转的效果 */
        this.shape._rotate();

        /* 设置绘制参数 */
        context.strokeStyle = config.activeColor;
        context.lineWidth = 2;
        context.lineCap = "round";
        context.lineJoin = "round";

        /* 选择框的间隙 */
        const gap = this.gap;
        context.strokeRect(x - gap, y - gap, w + gap * 2, h + gap * 2);

        /* 恢复 */
        context.closePath();
        context.restore();

        /* 绘制被添加的组件 */
        for (const i of this.action /* .concat(this.operates) */) {
            if (i) {
                i.draw();
            }
        }

    }


    /**
     * 点击事件
     * @param x
     * @param y
     * @returns {boolean}
     */
    click(x, y) {

        this.active = null; //重置被激活的组件

        /* 检测点击 */
        for (const i of Object.values(this.action)) {
            /* 如果没有检测到被选图形  */
            if (!this.active) {
                if (i && i.click(x, y)) {
                    this.active = i; //记录被点击的对象
                    return true;
                }
            }
        }

        return false;
    }


    /**
     *  图形移动
     * @param coords 位置数组
     * @param offset 触摸起始点和原点的偏差值
     * @returns {boolean}
     */
    move(coords, offset) {
        this.active.move(coords, offset);
    }


    /**
     * 点击事件
     * @param coords 触摸点
     */
    tap(coords) {
        this.active.tap(coords);
    }


    /**
     * 点击事件
     * @param coords 触摸点
     */
    up(coords) {
        this.active.up(coords);
    }

}