/* eslint-disable */
/* 状态操作 */
import {toFixedNumber} from "../../utils/math";
import {cloneDeep} from "../../utils/common";
import {getCoordsBox, radToDegree, rotateVector} from "../../utils/vector";

export default {


    /**
     * 设置激活状态
     * @param status
     * @param flag 是否触发舞台事件
     * @private
     */
    _active(status, flag = true) {

        /* 未改变 */
        if (status === this.actived) return;

        /* 如果可以被激活 */
        if (this.canActive && status) {
            this.emit('active', {type: 'active', shape: this});
            this.stage().emit("active", {type: 'active', shape: this});
            this.actived = status;
        } else {
            /* 失去焦点 */
            this.emit('deactivate', {type: 'deactivate', shape: this});
            /* 切换激活图形时，不触发 */
            if (flag) {
                this.stage().emit("deactivate", {type: 'deactivate', shape: this});
            }
            this.actived = false;
        }

        /* 重新渲染 */
        this.render();
    },

    /**
     * 切换激活状态
     */
    active() {
        this._active(true);
    },


    /**
     * 取消激活
     */
    deactivate(flag = true) {
        this._active(false, flag);
    },


    /**
     * 切换激活状态
     */
    toggle() {
        this._active(!this.actived);
        return this.actived;
    },


    /**
     * 导出所有属性
     * @return {object}
     */
    props(options = {}) {

        /* 导出配置 */
        const config = Object.assign({
            element: true, //导出绑定的DOM
            private: true, //私有属性
            relative: false, //转换相对坐标
            ignore: [] //忽略的属性
        }, options)

        /* 空对象 */
        const props = Object.create(null);

        /* 遍历所有属性 */
        for (let i in this) {


            if (config.ignore.indexOf('i') > -1) {
                continue;
            }

            /* 忽略方法属性 */
            if (typeof this[i] == "function") {
                continue;
            }


            /* 是否需要忽略私有属性 */
            if (!config.private && i.startsWith('_')) {
                continue;
            }

            const type = typeof this[i]; //类型

            /* 忽略DOM元素 */
            if (this[i] !== null && (type === 'object')) {
                /* 如果是DOM */
                if (this[i].nodeType !== undefined && !config.element) {
                    continue;
                }
            }


            /* 复制私有属性，不进行深拷贝 */
            if (i.startsWith('_')) {
                props[i] = this[i];
                /* DOM不进行深拷贝 */
            } else if (this[i] !== null && type === 'object' && this[i].nodeType !== undefined) {
                props[i] = this[i];
            } else {
                props[i] = cloneDeep(this[i], config)
            }


        }

        /* 索引位置 */
        props.zIndex = this.getIndex();
        props.actived = false; //非激活

        /* 是否需要转化为相对单位 */
        if (config.relative) {
            return Object.assign(props, this.relative());
        } else {
            return props;
        }


    },


    /**
     * 保存为JSON
     * @return {string}
     */
    toJson(config) {

        /* 空对象 */
        const props = this.props(Object.assign({
            element: false,
            private: false
        }, config));

        /* 索引位置 */
        if (this._stage) {
            props.zIndex = this._stage.getIndex(this);
        }

        /* 属性 */
        return JSON.stringify(props);
    },

    /**
     * 获取图形的边界范围
     */
    bounds() {
    },


    /**
     * 获取旋转之后的坐标位置
     */
    getRotateCoords() {

        /* 边界范围 */
        const bounds = this.bounds();
        const coords = []; //四个坐标

        /* 开始计算之后的坐标旋转 */
        coords.push(rotateVector(
            {x: bounds.x, y: bounds.y},
            radToDegree(this.rotateRadius),
            this.getCenterPoint()
        ));

        coords.push(rotateVector(
            {x: bounds.x + bounds.w, y: bounds.y},
            radToDegree(this.rotateRadius),
            this.getCenterPoint()
        ));

        coords.push(rotateVector(
            {x: bounds.x + bounds.w, y: bounds.y + bounds.h},
            radToDegree(this.rotateRadius),
            this.getCenterPoint()
        ));

        coords.push(rotateVector(
            {x: bounds.x, y: bounds.y + bounds.h},
            radToDegree(this.rotateRadius),
            this.getCenterPoint()
        ));

        return coords;
    },


    /**
     * 获取旋转之后的边界范围
     */
    getRotateBounds() {

        /* 边界范围 */
        if (this.rotateRadius === 0) {
            return this.bounds();
        }

        return getCoordsBox(this.getRotateCoords());
    },

    /**
     * 获取舞台对象
     */
    stage() {
        return this._stage;
    }
    ,

    /**
     * 获取上下文对象
     */
    getContext() {
        return this._context;
    }
    ,


    /**
     * 获取所在图层
     */
    getIndex() {
        /* 舞台对象 */
        const parent = this.stage();
        /* 没有添加到画布 */
        if (!parent) return -1;
        /* 获取索引位置 */
        return this._group ? this._group.getShapeIndex(this) : parent.getIndex(this);
    }
    ,


    /**
     * 图层移动
     */
    moveIndex(target, action = true, force = false) {

        /* 不可以移动图层 */
        if (!this.canSort && !force) return -1;

        /* 获取索引位置 */
        const index = this.getIndex();

        /* 范围判断 */
        if (index < 0) {
            return -1; // 索引超出数组范围，返回false
        }

        /* 所有图形 */
        const shapes = this._group ? this._group.getShapes() : this.stage().shapes();

        /* 范围判断 */
        if (target < 0 || target >= shapes.length) {
            return -1; // 索引超出数组范围，返回false
        }

        const shape = this; //当前图形

        /* 判断移动的方向 */
        const direction = target === 0 ? 0 : (target > index ? 1 : -1);

        /* 当前目标方向 */
        let currentIndex = target;

        /* 如果不是强制性移动，则需要检测 */
        if (!force) {

            /* 循环遍历可移动的目标 */
            do {

                /** @type Shape */
                const currentshape = shapes[currentIndex];

                if (currentshape.canSort) {
                    break; // 找到可以移动的元素，退出循环
                }

                currentIndex += direction;

            } while (currentIndex > 0 && currentIndex < shapes.length)
        }


        /* 超出范围 */
        if (currentIndex < 0 || currentIndex >= shapes.length) {
            return -1;
        }


        /* 目标是元素自身 */
        if (currentIndex === index) {
            return -1;
        }

        /* 是否需要执行实际操作 */
        /* 不需要则直接返回模板索引 */
        if (!action) {
            return currentIndex;
        }


        /* 触发事件 */
        this.emit('before-sort', {
            type: 'before-sort',
            shape,
            index: currentIndex
        })

        /* 往上继续抛出事件 */
        this.stage().emit('before-sort', {
            type: 'before-sort',
            index: currentIndex,
            shape
        })


        /* 移动对应的元素 */
        if (direction === 1) {
            for (let i = index; i < currentIndex; i++) {
                shapes[i] = shapes[i + 1];
            }
        } else {
            for (let i = index; i > currentIndex; i--) {
                shapes[i] = shapes[i - 1];
            }
        }

        /* 改变图层 */
        shapes[currentIndex] = shape;

        /* 触发事件 */
        this.emit('after-sort', {
            type: 'after-sort',
            shape,
            index: currentIndex
        })

        /* 往上继续抛出事件 */
        this.stage().emit('after-sort', {
            type: 'after-sort',
            shape,
            index: currentIndex
        });


        /* 重新渲染 */
        this.render();
        return currentIndex;

    }
    ,


    /**
     * 图层顶层
     */
    moveTop(action = true) {
        return this.moveIndex(this.stage().shapes().length - 1, action);
    }
    ,


    /**
     * 图层底层
     */
    moveBottom(action = true) {
        return this.moveIndex(0, action);
    }
    ,

    /**
     * 图层上移
     */
    forward(action = true) {
        return this.moveIndex(this.getIndex() + 1, action);
    }
    ,


    /**
     * 图层下移
     */
    backward(action = true) {
        return this.moveIndex(this.getIndex() - 1, action);
    }
    ,


    /**
     * 销毁
     */
    destroy(flag = false) {

        /* 从图形数组中移除 */
        if (this._stage) {
            /* 如何已经编组 */
            (this._group ? this._group : this.stage()).remove(this, flag);
            this.render(); //重新绘制
        }
    }
    ,


    /**
     * 设置上下文
     */
    bindContext(context) {
        this._context = context
    }
    ,


    /**
     * 获取图形非透明的边界范围
     * @return {{w: string, x: string, h: string, _w: string, y: string, _x: string, _h: string, _y: string}}
     */
    ColorBounds() {


        /* 获取离屏画布 */
        const bounds = this.stage().bounds(); //画布
        const offScreen = this.stage().createCanvas(bounds.w, bounds.h);
        const offContext = offScreen.context; //不显示的临时canvas

        /* 清空离屏画布 */
        offContext.beginPath();
        offContext.clearRect(0, 0, bounds.w, bounds.h);

        /* 保存状态 */
        offContext.save(false);
        this._rotate(offContext); //旋转

        /* 绘制 */
        offContext.drawImage(this.image, this.x, this.y, this.w, this.h);
        offContext.restore(); //恢复状态

        /* 获取像素值 */
        const imageData = offContext.getImageData(0, 0, Math.round(bounds.w), Math.round(bounds.h));

        /* 初始边界范围 */
        let minX = bounds.w;
        let minY = bounds.h;
        let maxX = 0;
        let maxY = 0;


        /* 开始遍历 */
        for (let y = 0; y < bounds.h; y++) {
            for (let x = 0; x < bounds.w; x++) {
                const index = (y * bounds.w + x) * 4;
                const alpha = imageData.data[index + 3];
                if (alpha > 0) {
                    minX = Math.min(minX, x);
                    minY = Math.min(minY, y);
                    maxX = Math.max(maxX, x);
                    maxY = Math.max(maxY, y);
                }
            }
        }

        /* DPR */
        const drp = toFixedNumber(this.stage().dpr(), 3);

        return {
            _x: toFixedNumber(minX, 3),
            _y: toFixedNumber(minY, 3),
            _w: toFixedNumber((maxX - minX), 3),
            _h: toFixedNumber((maxY - minY), 3),
            x: toFixedNumber((minX * drp), 3),
            y: toFixedNumber((minY * drp), 3),
            w: toFixedNumber(((maxX - minX) * drp), 3),
            h: toFixedNumber(((maxY - minY) * drp), 3)
        }

    }
    ,


    /**
     * 获取图形边界
     * @param force
     * @return {{w: string, x: string, h: string, _w: string, y: string, _x: string, _h: string, _y: string}}
     */
    getColorBounds(force = false) {

        /* 强制刷新获取 */
        if (force) {
            return this.ColorBounds();
        }

        /* 没有初始化过边界 */
        if (!this._colorBounds) {
            this._colorBounds = this.ColorBounds();
        }

        return this._colorBounds;
    }
    ,


    /**
     * 当前图形的参照区域
     */
    getReference() {

        const stage = this.stage(); //舞台

        /* 如果有编组 */
        if (this._group) {

            const bounds = this._group.bounds();

            return {
                x: bounds.x * stage.dpr(),
                y: bounds.y * stage.dpr(),
                w: bounds.w * stage.dpr(),
                h: bounds.h * stage.dpr(),
                _x: bounds.x,
                _y: bounds.y,
                _w: bounds.w,
                _h: bounds.h
            }
        }

        const diyBounds = stage.getDiyBounds(); //DIY区域

        /* 百分比转换参考的边界范围 */
        return diyBounds && this.reference === 'diy' ? diyBounds : stage.bounds();

    }
    ,


    /**
     * 能够展示边界
     */
    canShowOutline() {
        return this.stage().canShowOutline();
    }
    ,


    /**
     * 复制
     */
    async copy() {

        const stage = this.stage();

        /* 无舞台存在 */
        if (stage) {

            /* 复制开始 */
            stage.emit('before-copy', {
                type: 'before-copy',
                shape: this
            });

            /* 复制图形属性 */
            const props = this.props({
                element: true, //导出绑定的DOM
                private: false, //私有属性
                relative: false
            });

            /* 参照舞台 */
            props.reference = 'stage';

            /* 偏移 */
            props.x += 10;
            props.y += 10;

            /* 未激活 */
            this.actived = false;

            /* 加载属性 */
            const shape = stage.load(props, false);

            /* 图形初始化 */
            await stage.add(shape);
            shape.reference = 'diy'; //参照diy区域
            stage.active(shape); //激活图形

            /* 复制开始 */
            stage.emit('after-copy', {
                type: 'after-copy',
                shape: this
            });


        } else {
            return false;
        }

    }
    ,


    /**
     * 微调
     */
    adjustX(gap = 1) {

        /* 调整之前 */
        if (!this._group) {
            this.emit("before-adjust", {type: 'before-adjust', shape: this, direction: 'x'});
        }

        this.x += gap;

        /* 调整之后 */
        if (!this._group) {
            this.emit("after-adjust", {type: 'after-adjust', shape: this, direction: 'x'});
        }


        this.render();


    }
    ,


    /**
     * 微调
     */
    adjustY(gap = 1) {

        /* 如果编组了，就不触发 */
        if (!this._group) {
            this.emit("before-adjust", {type: 'before-adjust', shape: this, direction: 'y'});
        }


        this.y += gap;

        /* 调整之后 */
        if (!this._group) {
            this.emit("after-adjust", {type: 'after-adjust', shape: this, direction: 'y'});
        }

        this.render();


    },


    /**
     * 水平翻转
     */
    flipX() {

        /* 如果编组了，就不触发 */
        if (!this._group) {
            this.emit("before-flip", {type: 'before-flip', shape: this, direction: 'x'});
        }


        this.flip.x = this.flip.x * -1;


        /* 调整之后 */
        if (!this._group) {
            this.emit("after-flip", {type: 'after-flip', shape: this, direction: 'x'});
        }


        this.render();
    }
    ,


    /**
     * 垂直翻转
     */
    flipY() {

        /* 如果编组了，就不触发 */
        if (!this._group) {
            this.emit("before-flip", {type: 'before-flip', shape: this, direction: 'y'});
        }


        this.flip.y = this.flip.y * -1;


        /* 调整之后 */
        if (!this._group) {
            this.emit("after-flip", {type: 'after-flip', shape: this, direction: 'y'});
        }


        this.render();
    }
    ,


    /**
     * 绑定指定的组合
     */
    group(group) {
        this._group = group;
    }
    ,


    /**
     * 解绑Group
     */
    ungroup(adjust = true) {

        /* 从编组中移除自己 */
        this._group.remove(this, true, adjust);
        this._group = null;

        /* 暂停事件触发 */
        this.stage().pauseEvent('before-add');
        /* 新增到舞台 */
        this.stage().add(this);
        /* 恢复事件 */
        this.stage().resumeEvent('before-add');
    }
    ,


    /**
     * 设置属性
     */
    setAttr(attr, value) {
        this[attr] = value;
        this.render();
    }
    ,


    /**
     * 获取中心点
     * @returns {{x: *, y: *}}
     */
    getCenterPoint() {
        let {x, y, w, h} = this.bounds();
        return {x: x + w / 2, y: y + h / 2};
    }
    ,


    /**
     * 通过一个新的中心点计算原坐标
     */
    getReferCenterCoords(newCenter) {

        /* 获取中心点 */
        const center = this.getCenterPoint(true);

        return {
            x: this.x + (newCenter.x - center.x),
            y: this.y + (newCenter.y - center.y),
        }
    }

}
