/* eslint-disable */
import Shape from "@/diy/proto/shape";
import getVectorCenter, {getBoundingBox, radToDegree, reverseRotatePoint, rotateVector} from "@/diy/utils/vector";
import {percentToValue, toFixed} from "@/diy/utils/math";


/**
 * @date 2023/10/17
 * @author 爱心发电丶
 * Group初始化，new之后，通过一个同步的init，同步加载所有图形
 * 有两种初始化的情形，第一种是 json导入，Group已经自带坐标和大小了，需要图形参照group来初始化
 * 第二种事直接选择图形进行编组，编组之后重新计算Group的坐标和边界
 */
export default class Group extends Shape {

    /* 构造函数 */
    constructor(config = {}) {

        /* 初始化父类构造函数 */
        super(Object.assign({
            x: null, //原点x坐标
            y: null, //原点y坐标
            w: null, //绘制的长宽
            h: null, //绘制的长宽
            shapes: [], //包含哪些图形
            type: "Group", //图形类型
            canFlip: true, //是否允许翻转图片
            ratio: 1, //长宽比例
            button_2_Text: "解除",
            canDo: true, //是否响应额外动作
            fit: false, //初始化时候自动调整居中
            rule: "bind", //初始化之后
            _activeChild: null, //内部被激活的图形
            // 翻转类型
            flip: {
                x: 1,
                y: 1
            },
            _inited: false,//编组加载完毕
            _initedBounds: false, //初始化包围盒
        }, config));

    }


    /**
     * 属性绑定
     * @param config
     */
    each(config) {
        /* 覆盖配置 */
        for (let i in config) {
            /* 导出处理编组图形的属性 */
            if (i === 'shapes') {
                for (let k = 0; k < config[i].length; k++) {
                    this.shapes[k].each(config[i][k]);
                }
            } else {
                this[i] = config[i];
            }
        }
    }


    /**
     * 初始化，计算自身的边界范围
     */
    async _init() {

        /* 已经添加到上下文 */
        if (this._context) {

            /* 没有图形 */
            if (this.shapes.length === 0) {
                this.destroy(true);
            }


            /* 去除编组组件 */
            this.shapes = this.shapes.filter(shape => {
                return shape.type !== "Group";
            });

            const that = this; //对象
            const bounds = that.getReference();

            /* 如果有百分数，转换为数值 */
            if (that.x) {
                that.x = percentToValue(this.x, bounds._w) + bounds._x;
            }
            if (that.y) {
                that.y = percentToValue(this.y, bounds._h) + bounds._y;
            }
            if (that.w) {
                that.w = percentToValue(this.w, bounds._w);
            }
            if (that.h) {
                that.h = percentToValue(this.h, bounds._h);
            }


            /* 如果有宽度，没高度，通过长宽比计算高度 */
            if (this.w && !this.h) {
                this.h = this.w / this.ratio
            }

            /* 如果有宽度，没高度，通过长宽比计算高度 */
            if (!this.w && this.h) {
                this.w = this.h * this.ratio
            }

            /* 需要自动调整 */
            if (this.w && this.h && this.fit) {

                /* 超出大小 */
                if (this.w > bounds._w) {
                    this.w = bounds._w;
                    this.h = this.w / this.ratio;
                }

                /* 超出大小 */
                if (this.h > bounds._h) {
                    this.h = bounds._h;
                    this.w = this.h * this.ratio;
                }

                /* 计算图片坐标，居中 */
                this.x = (bounds._w - this.w) / 2 + bounds._x;
                this.y = (bounds._h - this.h) / 2 + bounds._y;
            }


            const shapes = []; //图形组
            const task = [];  //加载任务

            /* 标识 */
            let is_shape = false; //纯shape


            /* json初始化 */
            /* 循环初始化所有图形 */
            /** @type Shape */
            for (let shape of this.shapes) {

                /* 如果没有构造函数，代表是json串 */
                if (!shape.constructor || shape.constructor.name === 'Object') {


                    shape = this.stage().load(shape, false);

                    /* 设置图形的配置 */
                    shape.each({
                        _stage: this.stage(),
                        _context: this.getContext(),
                        _group: this,
                        actived: false, //是否被激活
                        clicked: false, //是否被点击
                    });

                    /* 添加到并发处理 */
                    task.push(shape.init());

                } else {


                    /* 从舞台移除 */
                    shape.destroy(true);

                    /* 设置图形的配置 */
                    shape.each({
                        _stage: this.stage(),
                        _context: this.getContext(),
                        actived: false, //是否被激活
                        clicked: false, //是否被点击
                    });

                    /* 绑定编组 */
                    shape.group(this);

                    /* 标记 */
                    is_shape = true;
                }

                shapes.push(shape);
            }

            /* 等待处理完毕 */
            if (task.length > 0) {
                await Promise.all(task);
            }


            /* 保存 */
            this.shapes = shapes;

            /* 是图形组，计算包围盒 */
            if (is_shape) {

                /* 计算包围盒 */
                this._adjust();

                /* 中心点 */
                const center = this.getCenterPoint();

                /* 取消所有图形的编组 */
                for (let i of this.getShapes(true)) {

                    /* 重新计算旋转之后中心点的角度 */
                    const coords = reverseRotatePoint(
                        i.getCenterPoint(),
                        radToDegree(i.rotateRadius),
                        center
                    );

                    /* 获取原坐标 */
                    const newCoords = i.getReferCenterCoords(coords);

                    /* 移动 */
                    i.adjustX(newCoords.x - i.x);
                    i.adjustY(newCoords.y - i.y);

                    /* 文本需要重新边界 */
                    if (i.type === 'Text') {
                        i.updateBounds();
                    }

                }

            }


            /* 标记初始化完毕，可以渲染了 */
            this._inited = true;
            this.fit = false; //加载后标记为不自适应

            /* 保存长宽比例 */
            this.ratio = toFixed(this.w / this.h, 4);

            /* 师傅需要自动解绑 */
            if (this.rule === 'unbind') {
                this.unbind();
            }


            /* 加载完成 */
            this.emit('loaded', {
                shape: this,
                type: 'loaded'
            });


            this.render(); //渲染
        }


    }


    /**
     * 子元素点击
     */
    clickText(x, y, flag = false, coords = []) {

        /* 遍历内部图形 */
        for (const i of this.shapes /* .concat(this.operates) */) {

            /* 忽略非text图形 */
            if (i.type !== 'Text') {
                continue;
            }

            if (i) {
                /* 是否点击了内部图形 */
                if (i.click(x, y, flag, coords)) {

                    /* 触发点击事件 */
                    this.stage().emit("click-group-text", {
                        type: 'click-group-text',
                        group: this,
                        shape: i
                    });


                    /* 内部被激活的图形 */
                    this._activeChild = i;

                    return true;
                }
            }
        }


        /* 内部被激活的图形 */
        this._activeChild = null;
    }


    /**
     * 绘制图形路径
     * @private
     */
    _draw() {

        /* 编组加载完毕 */
        if (!this._inited) return;

        /** @type {CanvasRenderingContext2D} */
        const context = this._context;

        let {
            x,
            y,
            w,
            h
        } = this.bounds(); //获取位置、长宽

        context.beginPath();
        this._rotate(); //旋转

        context.rect(x, y, w, h);
        context.closePath();
    }


    /**
     * 获取中心点
     * @returns {{x: *, y: *}}
     */
    getCenterPoint() {
        let {x, y, w, h} = (this._group ? this._group : this).bounds();
        return {x: x + w / 2, y: y + h / 2};
    }


    /**
     * 处理旋转
     */
    _rotate(ctx = null) {

        /** @type {CanvasRenderingContext2D} */
        const context = ctx ? ctx : this.getContext(); //上下文

        /* 是否围绕中心点旋转 */
        const rectCenterPoint = this.rotateAroundCenter ? this.getCenterPoint() : {x: this.x, y: this.y}; // 矩形中心点

        /* 是否需要旋转 */
        if (this.rotateRadius !== 0) {
            context.translate(rectCenterPoint.x, rectCenterPoint.y);
            context.rotate(this.rotateRadius); //旋转
            context.translate(-rectCenterPoint.x, -rectCenterPoint.y);
        }

    }


    /**
     *  图形绘制
     *  @param flag 绘制选择状态
     */
    draw(flag = true) {

        /* 没有绑定图形 */
        if (!this.shapes.length || !this._inited) return false;

        /* 绘制被添加的组件 */
        for (const i of this.shapes /* .concat(this.operates) */) {
            if (i) {
                i.bindContext(this._context);
                i.draw();
            }
        }


        // 如果被选中则绘制被选中的效果
        // 离屏渲染不绘制
        // 是否选择、裁剪、离屏
        // 选择状态的反选
        if (flag && this.actived) {
            const that = this; //对象
            /* 判端选择状态是即时绘制还是队列处理 */
            if (this.queue) {
                this.stage().addQueueTask(() => {
                    that._action.draw(); //绘制选中状态
                })
            } else {
                that._action.draw(); //绘制选中状态
            }
        }


    }


    /**
     *  图形移动
     * @param coords 位置数组
     * @param offset 触摸起始点和原点的偏差值
     * @returns {boolean}
     */
    move(coords, offset) {


        /* 编组加载完毕 */
        if (!this._inited) return;

        /* 多个手指触摸时，不进行拖动，会导致反复横跳 */
        if (coords.length > 1) {
            return false;
        }

        /* 处理DOM变化 */
        const {
            x,
            y
        } = coords[0];


        /* 坐标未发生改变 */
        if (x === this._previous.x && y === this._previous.y) {
            return false;
        }


        /* 记录当前的触发点 */
        this._previous.x = x;
        this._previous.y = y;

        /* 如果处于act状态 */
        if (this.actived && this._action && this._acting) {
            /* 触发 */
            this._action.move(coords, offset);
            /* 渲染 */
            this.render();
            return true;
        }


        /* 移动事件 */
        this.emit("move", {type: 'move', coords, offset, shape: this});
        this.stage().emit("move", {type: 'move', coords, offset, shape: this});

        /* 当前坐标 */
        let _x = x - offset.x;
        let _y = y - offset.y;

        /* 计算坐标的变化值 */
        let translateX = _x - this.x;
        let translateY = _y - this.y;


        /* 更新内部组件的坐标 */
        for (const i of this.shapes /* .concat(this.operates) */) {
            if (i) {
                i.adjustX(translateX);
                i.adjustY(translateY);
            }
        }


        /* 更新图形的位置 */
        this.x = _x;
        this.y = _y;


        /* 渲染 */
        this.render();
        return true;
    }


    /**
     * 图形缩放，围绕中心点缩放
     * @param zoom 缩放的比例
     * @param coords 触摸点
     */
    scale(zoom, coords) {

        /* 编组加载完毕 */
        if (!this._inited) return;


        /* 绘制被添加的组件 */
        for (const i of this.shapes /* .concat(this.operates) */) {
            if (i) {
                i.scale(zoom, coords);
            }
        }


        /* 记录初始大小 */
        if (!this._startBounds) {

            /* 中心点 */
            const center = this.stage().getLimit() && coords.length > 1 ? getVectorCenter(coords[0], coords[1]) : (!!this._group ? this._group.getCenterPoint() : {
                x: this.x + this.w / 2,
                y: this.y + this.h / 2
            });

            /* 初始边界范围 */
            this._startBounds = {
                w: this.w,
                h: this.h,
                x: this.x,
                y: this.y,
                centerX: center.x,
                centerY: center.y
            };
        }


        /* 取点位 */
        zoom = toFixed(zoom, 2);

        /* 根据缩放比例重新计算位置和大小 */
        const {x, y, w, h, centerX, centerY} = this._startBounds;

        this.x = centerX - (centerX - x) * zoom;
        this.y = centerY - (centerY - y) * zoom;

        this.w = w * zoom;
        this.h = h * zoom;

        /* 触发事件 */
        this.emit("scale", {type: 'scale', coords, zoom, shape: this});
        this.stage().emit("scale", {type: 'scale', coords, zoom, shape: this});


        /* 渲染 */
        this.render();
        return true;
    }


    /**
     * 图形旋转事件
     * @param rad 旋转的弧度
     * @param coords 触摸点
     * @returns {boolean}
     */
    rotate(rad, coords) {

        /* 编组加载完毕 */
        if (!this._inited) return;


        /* 绘制被添加的组件 */
        for (const i of this.shapes /* .concat(this.operates) */) {
            if (i) {
                i.rotate(rad, coords);
            }
        }


        /* 单指旋转 */
        if (coords.length === 1) {

            /* 记录开始旋转时的角度 */
            if (!this._startAgree) {
                this._startAgree = this.rotateRadius;
            }

            this.rotateRadius = (this._startAgree + rad);

        } else {

            /* 获取配置 */
            const config = this.stage().getConfig();

            /* 大于最小可转动 */
            if (Math.abs(rad - this.rotateRadius) > config.minRotatable) {
                this.rotateRadius += rad / (100 - config.sensitivity); //计算弧度
            }
        }


        /* 触发事件 */
        this.emit("rotate", {type: 'rotate', coords, rad, shape: this});
        this.stage().emit("rotate", {type: 'rotate', coords, rad, shape: this});

        /* 渲染 */
        this.render();
        return true;
    }


    /**
     * 点击事件
     * @param coords 触摸点
     */
    tap(coords) {

        /* 编组加载完毕 */
        if (!this._inited) return;

        /* 触发动作结束的事件 */
        this.emit("tap", {type: 'tap', coords, shape: this});
        this.stage().emit("tap", {type: 'tap', coords, shape: this});


        /* 如果处于act状态 */
        if (this.actived && this._action && this._acting) {
            /* 触发 */
            this._action.tap(coords);
            /* 渲染 */
            this.render();
            /* 返回状态 */
            return this.actived;
        }

        /* 判断分组内部的点击 */
        if (this.actived) {
            if (this.clickText(coords.x, coords.y, false, [coords])) {
                /* 返回状态 */
                return this.actived;
            }
        }


        /* 触发tap操作 */
        this.toggle();
        /* 渲染 */
        this.render();
        /* 返回状态 */
        return this.actived;
    }

    /**
     * 保存本次动作之后的图形数据
     * @param coords 触摸点
     */
    up(coords) {

        /* 编组加载完毕 */
        if (!this._inited) return;

        /* 绘制被添加的组件 */
        for (const i of this.shapes /* .concat(this.operates) */) {
            if (i) {
                i.up(coords);
            }
        }


        this._startAgree = null; //清除初始角度记录
        this._startBounds = null; //清除初始角度记录

        /* 如果处于act状态 */
        if (this.actived && this._action && this._acting) {
            /* 触发 */
            this._action.up(coords);
            /* 渲染 */
            this.render();
        }


        /* 触发动作结束的事件 */
        if (!!this._group) return; //如果编组了，就不触发

        this.emit("up", {type: 'up', coords, shape: this});
        this.stage().emit("up", {type: 'up', coords, shape: this});
    }


    /**
     * 水平翻转
     */
    flipX() {


        /* 如果编组了，就不触发 */
        if (!this._group) {
            this.emit("before-flip", {type: 'before-flip', shape: this, direction: 'x'});
        }

        this.flip.x = this.flip.x * -1;

        /* 绘制被添加的组件 */
        for (const i of this.shapes /* .concat(this.operates) */) {
            if (i) {
                i.flipX();
            }
        }


        /* 调整之后 */
        if (!this._group) {
            this.emit("after-flip", {type: 'after-flip', shape: this, direction: 'x'});
        }


        this.render();
    }


    /**
     * 垂直翻转
     */
    flipY() {

        /* 如果编组了，就不触发 */
        if (!this._group) {
            this.emit("before-flip", {type: 'before-flip', shape: this, direction: 'y'});
        }


        this.flip.y = this.flip.y * -1;

        /* 绘制被添加的组件 */
        for (const i of this.shapes /* .concat(this.operates) */) {
            if (i) {
                i.flipY();
            }
        }


        /* 调整之后 */
        if (!this._group) {
            this.emit("after-flip", {type: 'after-flip', shape: this, direction: 'y'});
        }


        this.render();
    }


    /**
     * 获取图形边界范围的坐标
     * @param corner
     * @returns {{x: (number|string|*), y: (number|string|*)}|{x: *, y: (number|string|*)}|{x: (number|string|*), y: *}|{x: *, y: *}|[{x: (number|string|*), y: (number|string|*)},{x: *, y: (number|string|*)},{x: (number|string|*), y: *},{x: *, y: *}]}
     */
    coords(corner = null) {

        const coords = [
            {x: this.x, y: this.y},
            {x: this.x + this.w, y: this.y},
            {x: this.x, y: this.y + this.h},
            {x: this.x + this.w, y: this.y + this.h},
            {x: this.x + (this.w / 2), y: this.y},
            {x: this.x + this.w, y: this.y + (this.h / 2)},
            {x: this.x, y: this.y + (this.h / 2)},
            {x: this.x + (this.w / 2), y: this.y + (this.h / 2)}
        ]

        if (corner === null) {
            return coords;
        } else {
            return coords[corner];
        }

    }


    /*
    * 获取边界范围
    * */
    bounds() {
        return {
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h
        }
    }


    /**
     * 相对位置
     */
    relative() {

        /* 舞台的边界范围 */
        const bounds = this.getReference();

        return {
            x: (((this.x - bounds._x) / bounds._w) * 100).toFixed(2) + "%",
            y: (((this.y - bounds._y) / bounds._h) * 100).toFixed(2) + "%",
            w: null,
            h: ((this.h / bounds._h) * 100).toFixed(2) + "%"
        }

    }


    /**
     * 微调
     */
    adjustX(gap = 1) {

        /* 调整之前 */
        if (!this._group) {
            this.emit("before-adjust", {type: 'before-adjust', shape: this, direction: 'x'});
        }

        /* 更新内部组件的坐标 */
        for (const i of this.shapes /* .concat(this.operates) */) {
            if (i) {
                i.x += gap;
            }
        }

        this.x += gap;

        /* 调整之后 */
        if (!this._group) {
            this.emit("after-adjust", {type: 'after-adjust', shape: this, direction: 'x'});
        }

        this.render();
    }


    /**
     * 微调
     */
    adjustY(gap = 1) {

        /* 如果编组了，就不触发 */
        if (!this._group) {
            this.emit("before-adjust", {type: 'before-adjust', shape: this, direction: 'y'});
        }


        /* 更新内部组件的坐标 */
        for (const i of this.shapes /* .concat(this.operates) */) {
            if (i) {
                i.y += gap;
            }
        }

        this.y += gap;


        /* 调整之后 */
        if (!this._group) {
            this.emit("after-adjust", {type: 'after-adjust', shape: this, direction: 'y'});
        }


        this.render();
    }


    /**
     * 复制
     */
    async copy() {

        const stage = this.stage();

        /* 无舞台存在 */
        if (stage) {

            /* 复制开始 */
            stage.emit('before-copy', {
                type: 'before-copy',
                shape: this
            });

            /* 复制图形属性 */
            const props = this.props({
                element: true, //导出绑定的DOM
                private: false, //私有属性
                relative: false,
                ignore: ['shapes']
            });


            /* 图形 */
            props.shapes = this.shapes.map(item => {

                /* 导出 */
                const props = item.props(
                    {
                        element: true, //导出绑定的DOM
                        private: false, //私有属性
                        relative: false
                    }
                );

                /* 转换 */
                props.x += 10;
                props.y += 10;

                return props;
            });

            /* 参照舞台 */
            props.reference = 'stage';

            /* 偏移 */
            props.x += 10;
            props.y += 10;

            /* 未激活 */
            this.actived = false;
            props.fit = false;

            /* 加载属性 */
            const shape = stage.load(props, false);

            /* 图形初始化 */
            await stage.add(shape);
            shape.reference = 'diy'; //参照diy区域
            stage.active(shape); //激活图形

            /* 复制开始 */
            stage.emit('after-copy', {
                type: 'after-copy',
                shape: this
            });


        } else {
            return false;
        }

    }


    /**
     * 计算当前的边界
     */
    _adjust() {
        /* 计算包围盒 */
        Object.assign(this, getBoundingBox(this.shapes.map(shape => {
            return shape.bounds();
        })));
    }


    /**
     * 取消编组
     */
    unbind() {


        /* 往上继续抛出事件 */
        this.stage().emit('before-unbind', {
            type: 'before-unbind',
            shape: this
        });


        /* 中心点 */
        const center = this.getCenterPoint();

        /* 取消所有图形的编组 */
        for (let i of this.getShapes(true)) {


            /* 文本需要重新边界 */
            if (i.type === 'Text') {
                i.updateBounds();
            }

            /* 解绑，并且不刷新包围盒 */
            i.ungroup(false);


            /* 重新计算旋转之后中心点的角度 */
            const coords = rotateVector(
                i.getCenterPoint(),
                radToDegree(i.rotateRadius),
                center
            );


            /* 获取原坐标 */
            const newCoords = i.getReferCenterCoords(coords);

            /* 移动 */
            i.adjustX(newCoords.x - i.x);
            i.adjustY(newCoords.y - i.y);

        }

        /* 强制销毁自身 */
        this.destroy(true);


        /* 往上继续抛出事件 */
        this.stage().emit('after-unbind', {
            type: 'after-unbind',
            shape: this
        });
    }


    /**
     * 获取所有图形
     */
    getShapes(flag = false, index = -1) {

        /* 获取指定图形 */
        if (index === -1) {

            if (flag) {
                return this.shapes.map(item => {
                    return item;
                });
            } else {
                return this.shapes;
            }

        } else {
            if (typeof index === "number") {
                return this.shapes[index] ? this.shapes[index] : null;
            } else if (typeof index === 'string') {
                for (let i of this.shapes) {
                    if (i.name === index) {
                        return i;
                    }
                }
            }
        }

    }


    /**
     * 获取位置
     * @param shape
     * @returns int
     */
    getShapeIndex(shape) {
        return this.shapes.indexOf(shape);
    }


    /**
     * 移除指定图形
     * @param shape {Shape}
     * @param flag
     * @param adjust
     * @returns {boolean}
     */
    remove(shape, flag = false, adjust = false) {

        /* 强制移除 */
        if (flag) {

            const index = this.shapes.indexOf(shape);

            if (index > -1) {
                this.shapes.splice(index, 1);
            }

            /* 重新计算包围盒 */
            if (adjust) this._adjust();
            return true;
        }

        /* 图形是否存在 */
        if (!shape) return false;
        if (!shape.canRemove) return false;


        /* 删除之前 */
        this.stage().emit("before-delete", {type: 'before-delete', shape});

        /* 移除指定name的图形 */
        this.shapes = this.shapes.filter(function (elem) {

            /* 图形是否可以移除 */
            if (!elem.canRemove) {
                return true;
            }

            /* 移除指定图形 */
            if (typeof shape == "string") {
                return elem.name !== shape;
            } else {
                return elem !== shape;
            }
        });


        /* 删除之后 */
        this.stage().emit("after-delete", {type: 'after-delete', shape});

        /* 重新计算包围盒 */
        if (adjust) this._adjust();

        /* eslint-disable-next-line */
        this.render(); //重新绘制
    }


    /**
     * 保存当前图形的配置的快照
     */
    save() {
        return {
            props: this.props(),
            shapes: this.getShapes(true)
        }
    }

    /**
     * 恢复保存的图形配置
     */
    restore(snap) {
        this.shapes = snap.shapes;
        this.each(snap.props);
    }


    /**
     * 被激活的图形
     */
    getActive() {
        return this._activeChild;
    }


    /**
     * 绑定指定的组合
     */
    group(group) {
        this._group = group;
    }


    /**
     * 解绑Group
     */
    ungroup(adjust = true) {

        /* 从编组中移除自己 */
        this._group.remove(this, true, adjust);
        this._group = null;

        /* 暂停事件触发 */
        this.stage().pauseEvent('before-add');
        /* 新增到舞台 */
        this.stage().add(this);
        /* 恢复事件 */
        this.stage().pauseEvent('before-add');
    }


}