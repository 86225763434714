import {createApp} from 'vue'
import App from './App.vue'
import {createPinia} from "pinia"
import {router} from "./router/index";
import batch____import from './utils/imports'
import goto_____bootstrap from "./utils/bootstrap";
import i18n from './locals'

/* 全局样式加载 */
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';

import { Lazyload, Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('zh-CN', enUS);

if(import.meta.env.PROD) {
    sessionStorage.removeItem('userToken');
}
// import VConsole from 'vconsole';

// new VConsole();

/* 初始化vue */
const app = createApp(App);
app.use(createPinia());//状态管理器
app.use(i18n);//国际化
app.use(router);//路由加载
app.use(Lazyload);
batch____import(app);//批量导入组件
goto_____bootstrap(); //引导系统初始化

app.mount('#app')
