/*
* @author 友人a丶
* @date 2022-07-11
*
* 加载axio拦截器
* */
import axios from "axios";
import apis from '@/service/api';
import config from "@/config";
import {getQueryVariable} from "@/common/common";
import Cookies from "js-cookie";

/*
* 是否需要注册认证头
* */
if (config.auth.mode === "token") {

    const query = getQueryVariable();


    let userInfo = localStorage.getItem(query.user_id);

    if(userInfo) {
        userInfo = JSON.parse(userInfo);
    }

    /* 如果存在token参数 */
    if (userInfo && !!userInfo.token) {
        Cookies.set(config.auth.token, userInfo.token, {path: location.path}); //保存token
    }

    // 登录的Token保存
    const xsrfHeaderName = config.auth.token; //认证头的名字
    axios.defaults.timeout = 120000;
    axios.defaults.withCredentials = true;
    axios.defaults.xsrfHeaderName = xsrfHeaderName;
    axios.defaults.xsrfCookieName = xsrfHeaderName;
}


// 不需要拦截的接口
const ignoreApi = {
    api: [
        apis.login
    ],
    includes(api) {

        /*
        * 判断当前请求的接口是否在忽略列表
        * */
        for (let item of ignoreApi.api) {
            let reg = new RegExp(`.*${item}.*`);
            if (reg.test(api)) {
                return true;
            }
        }
        return false;
    }
}

/*
* 注册响应拦截器
* */
export default function () {
    console.log("加载拦截器...");

    axios.interceptors.response.use(function (res) {
        console.log("请求接口：" + res.config.url)
        console.log(res);

        /*
        * 判断是否需要拦截
        * */
        if (ignoreApi.includes(res.config.url)) {
            return res;
        }

        return res;
    });


}
