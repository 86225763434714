/**
 * @date 2023/10/17
 * @author 爱心发电丶
 */
export default {
    /***** 抽象方法 ******/
    _draw: null, //抽象方法，由子类具体实现，绘制图形的路径
    _rotate: null, //抽象方法，旋转的逻辑
    _init: null, //抽象方法，由子类具体实现，初图形始化
    draw: null, //抽象方法，由子类具体实现，绘制图形
    /* 图形渲染 */
    render() {
        if (this._stage) {
            /* 重新渲染 */
            this._stage.render();
        }
    }
}