import getVectorCenter from "@/diy/utils/vector";
import {toFixed} from "@/diy/utils/math";

/**
 * @date 2023/10/17
 * @author 爱心发电丶
 */
export default {

    /**
     *  图形移动
     * @param coords 位置数组
     * @param offset 触摸起始点和原点的偏差值
     * @returns {boolean}
     */
    move(coords, offset) {


        /* 多个手指触摸时，不进行拖动，会导致反复横跳 */
        if (coords.length > 1) {
            return false;
        }

        /* 处理DOM变化 */
        const {
            x,
            y
        } = coords[0];


        /* 坐标未发生改变 */
        if (x === this._previous.x && y === this._previous.y) {
            return false;
        }

        /* 记录当前的触发点 */
        this._previous.x = x;
        this._previous.y = y;

        /* 如果处于act状态 */
        if (this.actived && this._action && this._acting) {
            /* 触发 */
            this._action.move(coords, offset);
            /* 渲染 */
            this.render();
            return true;
        }


        /* 移动事件 */
        this.emit("move", {type: 'move', coords, offset, shape: this});
        this.stage().emit("move", {type: 'move', coords, offset, shape: this});


        /* 更新图形的位置 */
        this.x = x - offset.x;
        this.y = y - offset.y;


        /* 渲染 */
        this.render();
        return true;
    },


    /**
     * 图形缩放，围绕中心点缩放
     * @param zoom 缩放的比例
     * @param coords 触摸点
     */
    scale(zoom, coords) {


        /* 记录初始大小 */
        if (!this._startBounds) {

            /* 中心点，还是触摸的中心点 */
            const center = this.stage().getLimit() && coords.length > 1 ? getVectorCenter(coords[0], coords[1]) : (!!this._group ? this._group.getCenterPoint() : {
                x: this.x + this.w / 2,
                y: this.y + this.h / 2
            });

            /* 初始边界范围 */
            this._startBounds = {
                w: this.w,
                h: this.h,
                x: this.x,
                y: this.y,
                centerX: center.x,
                centerY: center.y
            };
        }


        /* 取点位 */
        zoom = toFixed(zoom, 2);

        /* 根据缩放比例重新计算位置和大小 */
        const {x, y, w, h, centerX, centerY} = this._startBounds;

        this.x = centerX - (centerX - x) * zoom;
        this.y = centerY - (centerY - y) * zoom;

        this.w = w * zoom;
        this.h = h * zoom;

        /* 触发事件 */
        this.emit("scale", {type: 'scale', coords, zoom, shape: this});
        this.stage().emit("scale", {type: 'scale', coords, zoom, shape: this});


        /* 渲染 */
        this.render();
        return true;
    },


    /**
     * 图形旋转事件
     * @param rad 旋转的弧度
     * @param coords 触摸点
     * @returns {boolean}
     */
    rotate(rad, coords) {

        /* 单指旋转 */
        if (coords.length === 1) {

            /* 记录开始旋转时的角度 */
            if (!this._startAgree) {
                this._startAgree = this.rotateRadius;
            }

            this.rotateRadius = (this._startAgree + rad);

        } else {

            /* 获取配置 */
            const config = this.stage().getConfig();

            /* 大于最小可转动 */
            if (Math.abs(rad - this.rotateRadius) > config.minRotatable) {
                this.rotateRadius += rad / (100 - config.sensitivity); //计算弧度
            }
        }


        /* 触发事件 */
        this.emit("rotate", {type: 'rotate', coords, rad, shape: this});
        this.stage().emit("rotate", {type: 'rotate', coords, rad, shape: this});

        /* 渲染 */
        this.render();
        return true;
    },


    /**
     * 点击事件
     * @param coords 触摸点
     */
    tap(coords) {

        /* 触发动作结束的事件 */
        this.emit("tap", {type: 'tap', coords, shape: this});
        this.stage().emit("tap", {type: 'tap', coords, shape: this});


        /* 如果处于act状态 */
        if (this.actived && this._action && this._acting) {
            /* 触发 */
            this._action.tap(coords);
            /* 渲染 */
            this.render();
            /* 返回状态 */
            return this.actived;
        }

        /* 触发tap操作 */
        this.toggle();
        /* 渲染 */
        this.render();
        /* 返回状态 */
        return this.actived;
    },

    /**
     * 保存本次动作之后的图形数据
     * @param coords 触摸点
     */
    up(coords) {

        this._startAgree = null; //清除初始角度记录
        this._startBounds = null; //清除初始角度记录

        /* 如果处于act状态 */
        if (this.actived && this._action && this._acting) {
            /* 触发 */
            this._action.up(coords);
            /* 渲染 */
            this.render();
        }


        /* 触发动作结束的事件 */
        if (!!this._group) return; //如果编组了，就不触发
        this.emit("up", {type: 'up', coords, shape: this});
        this.stage().emit("up", {type: 'up', coords, shape: this});
    },


    /**
     * 导出被选图形
     * @param param
     * @return {Promise<Blob|string>}
     */
    export(param = {}) {
        return this.stage().export(Object.assign({
            elem: ['shape'],
            diy: false
        }, param), {
            fore: [this]
        })

    }
}