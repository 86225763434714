/**
 * @date 2023/10/17
 * @author 爱心发电丶
 * 通用的方法
 */


/**
 * 重命名对象的指定属性
 * @param obj
 * @param oldProp
 * @param newProp
 */
export function renameProperty(obj, oldProp, newProp) {
    // 检查对象是否包含旧属性
    if (obj.hasOwnProperty(oldProp)) {
        // 获取旧属性的值
        const prop = obj[oldProp];
        // 删除旧属性
        delete obj[oldProp];
        // 创建新属性并赋值
        obj[newProp] = prop;
    }
}


/**
 * 指定方向移动数组元素
 * @param arr
 * @param element
 * @param index 指定索引处
 * @returns {*}
 */
export function moveElem(arr, element, index) {

    /* 数组为空 */
    if (arr.length === 0) {
        return false;
    }

    /* 不包含指定元素 */
    if (!arr.includes(element)) {
        return false;
    }

    const currentIndex = arr.indexOf(element);
    const maxIndex = arr.length - 1;

    index = Math.max(0, Math.min(index, maxIndex));

    /* 未发生改变 */
    if (currentIndex === index) {
        return false;
    }

    arr.splice(currentIndex, 1);
    arr.splice(index, 0, element);

    return arr;
}


/**
 * 获取手势事件的所有坐标
 * @param ev
 * @returns {*[]}
 */
export function getPosOfEvent(ev) {
    //多指触摸， 返回多个手势位置信息
    if ("touches" in ev && ev.touches) {
        const posi = [];
        let src = null;

        for (let t = 0, len = ev.touches.length; t < len; t++) {
            src = ev.touches[t];
            posi.push({
                x: src.pageX,
                y: src.pageY
            });
        }
        return posi;
    } //处理PC浏览器的情况
    else {
        return [{
            x: ev.x,
            y: ev.y
        }];
    }
}

/**
 * 获取手势结束点
 * @param ev
 * @returns {*[]}
 */
export function getEnd(ev) {
    //多指触摸， 返回多个手势位置信息
    if ("changedTouches" in ev && ev.changedTouches) {
        const posi = [];
        let src = null;

        for (let t = 0, len = ev.changedTouches.length; t < len; t++) {
            src = ev.changedTouches[t];
            posi.push({
                x: src.pageX,
                y: src.pageY
            });
        }
        return posi;
    } //处理PC浏览器的情况
    else {
        return [{
            x: ev.x,
            y: ev.y
        }];
    }
}


/**
 * 上传文件
 * @return {Promise<unknown>}
 */
import load from "@/common/load";

export function chooseFile() {
    return new Promise((resolve) => {
        /* 不每次创建 */
        if (!window.chooseFileInput) {
            window.chooseFileInput = document.createElement('input');
            window.chooseFileInput.type = 'file'; //设置类型
        }
        
        window.chooseFileInput.value = ""; //重置
        const input = window.chooseFileInput;
        
        // 设置允许选择的文件类型
        input.accept = 'image/*'; // 可根据需求修改为所需文件类型
        
        /* 选择文件 */
        input.onchange = () => {
            load.loading('loading');
            const file = input.files[0];
            if(!file) {
                resolve(false);
            }else {

                const isCompressImage = file.size > 10 * 1024 * 1024;
                if(isCompressImage) {
                    resolve(compressAndUpload(file));
                }else {
                    resolve(file);
                }
            }
        };

        input.click();
    });
}

function compressAndUpload(file) {
    return new Promise((resolve, reject) => {
      const maxSize = 10 * 1024 * 1024; // 10MB
      let quality = 0.95; // 初始压缩质量
      const step = 0.1; // 每次调整压缩质量的步长
  
      const reader = new FileReader();
      reader.onload = () => {
        const compressedImage = new Image();
        compressedImage.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          canvas.width = compressedImage.width;
          canvas.height = compressedImage.height;
  
          ctx.drawImage(compressedImage, 0, 0, compressedImage.width, compressedImage.height);
  
          canvas.toBlob((resultBlob) => {
            if (resultBlob) {
              if (resultBlob.size > maxSize && quality > 0) {
                // 递归调用压缩函数，减小质量
                quality -= step;
                compressAndUpload(resultBlob).then(resolve).catch(reject);
              } else {
                // 最终上传
                resolve(resultBlob);
              }
            } else {
              reject(new Error('Failed to create Blob object'));
            }
          }, 'image/jpeg', quality); // 指定输出格式和压缩质量
        };
        compressedImage.src = reader.result;
      };
      reader.readAsDataURL(file);
  
      reader.onerror = (error) => {
        reject(error);
      };
    });
}
  
  

export function imageCompression(imageUrl) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Enable cross-origin resource sharing
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 4095; // Limit maximum width
            const maxHeight = 4095; // Limit maximum height
            let newWidth = img.width;
            let newHeight = img.height;

            if (img.width > maxWidth || img.height > maxHeight) {
                const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
                newWidth = img.width * ratio;
                newHeight = img.height * ratio;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            // Clear canvas content
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // Get compressed image data
            const imageData = canvas.toDataURL('image/jpeg', 0.8); // Adjust compression quality

            // Convert base64 to Blob object
            const blob = dataURItoBlob(imageData);

            // Create File object
            const compressedFile = new File([blob], 'compressed_image.jpg', { type: 'image/jpeg' });

            resolve(compressedFile);
        };

        img.onerror = (error) => {
            reject(error);
        };

        img.src = imageUrl;
    });
}

const dataURItoBlob = (dataURI)=> {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}
/**
 * 创建Image对象
 */
export function loadImage(url, callback, error = null, cache = true) {

    /* 图形缓存 */
    if (!window.hasImage) {
        window.hasImage = Object.create(null);
    }

    /* 判断是否缓存 */
    if (cache && Object.keys(window.hasImage).indexOf(url) > -1) {
        callback(window.hasImage[url]);
        return;
    }

    /* 图像对象 */
    const image = new window.Image();
    image.crossOrigin = 'anonymous'; //跨域
    image.src = url; //设置图源
    image.style.display = "none";

    /* 加载完毕 */
    image.onload = () => {

        /* 是否缓存 */
        if (cache) {
            window.hasImage[url] = image;
        }

        if (callback) {
            callback(image);
        }

    };

    /* 加载异常 */
    image.onerror = () => {
        console.warn(url + "图片加载失败！");
        if (error) {
            error();
        }
    }
}


/**
 * 下载Blob对象
 * @param blob
 * @param fileName
 */
export function downloadBlob(blob, fileName) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
}


/**
 * 延迟执行
 * @param callback
 * @param time
 */
export function delay(callback, time) {
    let timer = setTimeout(() => {
        clearTimeout(timer);
        callback();
    }, time);
}


/**
 * 节流函数
 * @param cb
 * @param wait
 * @returns {(function(...[*]): void)|*}
 */
export function throttle(cb, wait = 3000) {
    let previous = 0;
    return (...args) => {
        const now = +new Date();
        if (now - previous > wait) {
            previous = now;
            cb.apply(this, args);
        }
    }
}


/**
 * 简单的深拷贝函数
 * @param obj
 * @param config
 * @return {*[]|{}}
 */
export function cloneDeep(obj, config = {}) {

    const type = typeof obj; //类型

    /* 基础类型和Null */
    if (obj === null || type !== 'object') {
        return obj;
    }

    const clonedObj = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
        /* 忽略函数 */
        if (typeof obj[key] !== 'function') {

            if (!!obj[key] && !!obj[key].props) {
                clonedObj[key] = obj[key].props(config);
            } else {
                clonedObj[key] = cloneDeep(obj[key]);
            }

        }
    }

    return clonedObj;

}

export const debounce = (func, delay)=> {
    let timerId;
  
    return function (...args) {
      const context = this;
  
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
}